import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { save } from 'save-file';
import { CarUtil } from '../../utils/carUtil';
import { connect } from 'react-redux'
import { NumberUtil } from '../../utils/number-util'
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission';
import {AlertError, AlertWarning} from '../Alert/Alert';
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import {ExcelUtil} from "../../utils/excelUtil";
import { MonthUtil } from '../../utils/monthUtil'

// import { formatWithOptions } from 'date-fns/fp';
// const dateToString = formatWithOptions({locale: th}, 'dd MMMM yyyy');
// บัญชีผู้ทำการค้าของเก่า
class CarMerchantAccount extends Component{
    constructor (props) {
        super(props);
        this.state = {
            user: {},
            isLoading: false,
            date_start: new Date(),
            date_end: new Date(),
            lists: [],
            data_by: 'all', // all, byDate
            branches: [],
            branch_id: '',
            orders: [
                {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
                {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
                {order_id: 'car_license_asc', order_name: 'ทะเบียนรถใหม่ : ก-ฮ'},
                {order_id: 'car_license_desc', order_name: 'ทะเบียนรถใหม่ : ฮ-ก'},
            ],
            order_by: 'car_in_desc',
            person_vat_id: '',
            //
            month_year_list: [],
            carin_month_year: ''
        }
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        // this.loadCarInData = this.loadCarInData.bind(this)

        this.exportCarInSummaryReport = this.exportCarInSummaryReport.bind(this)
        this.loadBranchData = this.loadBranchData.bind(this)
        // this.loadGroupPermission = this.loadGroupPermission.bind(this)
        //
        this.loadCarInMonthList = this.loadCarInMonthList.bind(this)
        this.loadCarInDataMonth = this.loadCarInDataMonth.bind(this)
    }

    /*loadGroupPermission() {
        let type = this.props.type
        let menuID = this.state.menuID
        if (type === undefined) {

            return
        }

        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        }).catch(e => {
            console.log('error: ', e)
            this.setState({
                grouppermissions: []
            })
        })
    }*/

    componentDidMount () {
        this.loadBranchData()
        // this.loadGroupPermission()
        // this.loadCarInData()
        this.loadCarInMonthList()
    }

    loadCarInMonthList() {
        let uri = APIURL + '/car/list/month'
        axios.get(uri)
          .then(res => {
              if( res.data.length > 0) {
                  let months = res.data
                  let month_year_list =  months.map(month => {
                      let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
                      return {
                          ...month,
                          month_list_thai: month_list_thai
                      }
                  });

                  this.setState({
                      month_year_list: month_year_list
                  }, () => {
                      if (month_year_list.length > 0) {
                          let month_list = month_year_list[0].month_list
                          this.setState({
                              carin_month_year: month_list
                          }, () => this.loadCarInDataMonth(month_list))

                      }
                  })
              }
          }).catch(error => {

        })
    }

    // defaultChecked={this.state.data_by === 'byDate'}
    // defaultChecked={this.state.data_by === 'all'}

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    handleOnChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /*loadCarInData() {
        if(this.state.date_start > this.state.date_end){
            AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        // let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
        // let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

        let start_date = format(new Date(this.state.date_start), 'yyyy-MM-dd')
        let end_date = format(new Date(this.state.date_end), 'yyyy-MM-dd')

        this.setState({
            isLoading: true
        })

        const data_by = this.state.data_by
        const branch_id = this.state.branch_id
        const order_by = this.state.order_by
        const person_vat_id = this.state.person_vat_id

        const data = {
            start_date: start_date,
            end_date: end_date,
            data_by: data_by,
            branch_id: branch_id,
            order_by: order_by,
            person_vat_id: person_vat_id
        }

        // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
        /!*axios.post(APIURL + '/report_cost_summary/carin', data)
            .then(res => {
                let data = res.data
                let cars = data.cars
                let expenses = data.expenses

                this.setState({
                    isLoading: false,
                    lists: cars,
                    expenses: expenses
                })

            }).catch(error => {
            console.log('error:', error)
        })*!/

    }*/

    loadCarInDataMonth(data) {
        let branch_id = this.state.branch_id === '' ? 0 : Number(this.state.branch_id)
        let typeLoad = ''
        let start_date = ''
        let end_date = ''

        if(data === null) {
            typeLoad ='all'
        } else if (data === 'all') {
            typeLoad ='all'
        } else {
            typeLoad = 'date'
        }

        let month_list_thai = ''
        if(typeLoad === 'all') {
            start_date = 'all'
            end_date = 'all'
            month_list_thai = 'all'
        } else {
            const month_year_list = this.state.month_year_list.find(month_year => month_year.month_list === data)
            start_date = month_year_list.start_date
            end_date = month_year_list.end_date
            month_list_thai = month_year_list.month_list_thai
        }

        let dataReq = {
            start_date,
            end_date,
            branch_id
        }

        // let uri = APIURL + '/car/merchant_account/'+start_date+'/'+end_date+'/'+branch_id
        let uri = APIURL + '/report_car/merchant_account'

        axios.post(uri, dataReq).then(res => {
            let data = res.data
            let cars = data.cars

            this.setState({
                isLoading: false,
                lists: cars,
                // expenses: expenses
            })
        })
    }

    exportCarInSummaryReport(tableExcels) {
        let cars = this.state.lists
        if(cars.length === 0) {
            AlertError('ไม่พบข้อมูลรถเข้า')
            return
        }

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
        let branches = this.state.branches
        let branch_id = this.state.branch_id
        let branch_name = ''
        if(branch_id !== ''){
            let branch =  branches.filter(branch=>branch.branch_id === Number(branch_id))[0]
            branch_name = branch.branch_name
        }

        let title1 = 'บัญชีผู้ทำการค้าของเก่าประเภทรถยนต์เก่าที่ซื้อมาเพื่อขาย' + ' ' + ' ' + (branch_name === '' ? '( ทุกสาขา )' : 'สาขา : ' +  branch_name)

        const title = [
            {
                A: title1,
            },
            {
                A: 'พิมพ์เมื่อ : ' + curr,
            },
            {
                A: 'ลำดับ',
                B: 'ชนิดรถ/ยี่ห้อรถ',
                C: 'ลักษณะรถ',
                D: 'ทะเบียนรถ',
                E: 'เลขหมายเครื่องยนต์',
                F: 'เลขหมายตัวถัง',
                G: 'ได้รถมาจาก',
                H: 'วันที่ซื้อรถเข้า',
                I: 'ราคาที่ซื้อมา',
                J: 'จำหน่ายไปให้ใคร',
                K: 'ราคาที่ขาย',
                L: 'วันที่ขาย',
                M: 'หมายเหตุ',
            },
        ]


        const reportData = [...title, ...tableExcels]

        this.setState({isLoading: true})

        // หาว่าแถวไหนไม่มี vat F: VAT ซื้อ,
        let arrRowNoVat= tableExcels.map(row => {
            if (Number(row.E) === Number(row.G)){
                return row
            }
        })

        setTimeout(() => {
            const ws = XLSX.utils.json_to_sheet(reportData, { origin: 'A1', skipHeader: true });

            let wscols = [
                {wch:8},
                {wch:35},
                {wch:35},
                {wch:50},
                {wch:20},
                {wch:20},
                {wch:20},
                {wch:20},
                {wch:20},
                {wch:20},
                {wch:20},
                {wch:20},
                {wch:20},
            ];

            let last_row = cars.length + 2
            let last_col = 13

            let wsrows = [];
            for(let  i=1 ; i <=last_row + 1; i++){
                if (i===3){
                    wsrows.push({hpt: 40})
                }
                wsrows.push({hpt: 20})
            }

            ws['!cols'] = wscols;
            ws['!rows'] = wsrows

            let rangeTitle = { s: {r:2, c:0}, e: {r:2, c: last_col} };
            ExcelUtil.setCellBoldWithBorder(ws, rangeTitle);

            // content data
            let rangeOrder = { s: {r:3, c:0}, e: {r:last_row, c:0} };
            ExcelUtil.setCellCenterNormalWithBorder(ws, rangeOrder);

            let rangeCarCost = { s: {r:3, c:8}, e: {r:last_row, c:8} };
            ExcelUtil.setCellNumberFormatRightWithBorder(ws, rangeCarCost);

            let rangeRealSellPrice = { s: {r:3, c:10}, e: {r:last_row, c:10} };
            ExcelUtil.setCellNumberFormatRightWithBorder(ws, rangeRealSellPrice);

            last_row = last_row +1

            let rangeNumber = { s: {r:3, c:1}, e: {r:last_row, c: last_col} };
            ExcelUtil.setCellNumberFormatWIthBorder(ws, rangeNumber);

            let rangeSum = { s: {r:last_row, c:0}, e: {r:last_row, c: last_col} };
            ExcelUtil.setCellTextRightBoldWithBorder(ws, rangeSum);

            for(let i =0 ; i < arrRowNoVat.length ; i++) {
                let rowNoVatBuy = arrRowNoVat[i]

                if( rowNoVatBuy === undefined) continue;

                let rowOrder = rowNoVatBuy.A
                let row = 2+rowOrder
                let rangeBlue = { s: {r: row, c: 1}, e: {r:row, c:last_col} };
                ExcelUtil.setCellNumberFormatBlueWithBorder(ws, rangeBlue);
            }

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});

            let currFileName = format(new Date(), 'yyyy-MM-dd')
            let fileName = 'car_merchant_account'+'_'+currFileName
            FileSaver.saveAs(data, fileName + fileExtension);

            this.setState({isLoading: false})

        }, 1200)

    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    render () {
        const {
            lists
        } = this.state


        const viewed = true

        const emptyBody = <tr><td colSpan={10} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

        let sumCarCost = 0
        // let sumCostWithVat = 0
        // let sumCostWithExpenseNoVat = 0
        // let sumTotal = 0
        let sumRealSellPrice = 0
        let excelTables = []

        const listRows = lists.map((car, index) => {

            // let car_id = car.car_id
            const car_date_in = format(new Date(car.car_date_in), 'dd MMMM yyyy',  { locale: th });
            let car_cost = parseFloat(String(car.car_cost)).toFixed(2)

            let car_license_plate_new = car.car_license_plate_new.trim()
            let car_license_plate_old = car.car_license_plate_old.trim()

            let car_license_old_new = car.car_license_plate_old +' ' + car.province_name_old+ ' / '+ car.car_license_plate_new+' '+ car.province_name_new
            let car_license = car_license_plate_new === car_license_plate_old ? car.car_license_plate_new+' '+car.province_name_new : car_license_old_new

            let car_brand_name = car.car_brand_name
            let car_model_name = car.car_model_name
            // let car_year_name = car.car_year_name
            // let car_color_name = car.car_color_name
            let car_structure_name = car.car_structure_name
            let car_num_engines = car.car_num_engines
            let car_number = car.car_number
            let buy_car_from = car.buy_car_from

            let customer_fullname = car.customer_name === ''? '-' : car.customer_name +' '+car.customer_lastname
            let real_sell_price = car.real_sell_price === '' ? '-': NumberUtil.addCommas(car.real_sell_price)
            let real_sell_price_excel = car.real_sell_price === '' ? '-': Number(car.real_sell_price)
            let car_sell_date = car.car_sell_date === '' ? '-': format(new Date(car.car_sell_date), 'dd MMMM yyyy',  { locale: th });
            let sell_comment = car.sell_comment === '' ? '-' : car.sell_comment

            let car_name = car_brand_name+' '+car_model_name // +' / '+car_year_name+' / '+car_color_name

            sumCarCost += Number(car_cost)

            if (car.real_sell_price !== '') {
                sumRealSellPrice += Number(car.real_sell_price)
            }

            let order = index+1

            // excell data ที่เป็นตัวเลขต้องเป็น number ถึงจะเอาไปคำนวณต่อใน excel ได้
            let excelTableRow = {
                A: order,
                B: car_name,
                C: car_structure_name,
                D: car_license,
                E: car_num_engines,
                F: car_number,
                G: buy_car_from,
                H: car_date_in,
                I: Number(car_cost),
                J: customer_fullname,
                K: real_sell_price_excel,
                L: car_sell_date,
                M: sell_comment,
            }
            excelTables.push(excelTableRow)
            return (
                <tr key={index}>
                    <td className="text-center">{order}</td>
                    <td>{car_name}</td>
                    <td className="text-center text-nowrap"> {car_structure_name} </td>
                    <td className="text-center text-nowrap"> {car_license} </td>
                    <td className="text-center text-nowrap">  {car_num_engines} </td>
                    <td className="text-center text-nowrap">  {car_number} </td>
                    <td className="text-center text-nowrap">  {buy_car_from} </td>
                    <td className="text-center text-nowrap">{car_date_in}</td>
                    <td className="text-center text-nowrap">{NumberUtil.addCommas(car_cost)}</td>
                    <td className="text-center text-nowrap">{customer_fullname}</td>
                    <td className="text-center text-nowrap">{real_sell_price}</td>
                    <td className="text-center text-nowrap">{car_sell_date}</td>
                    <td className="text-center text-nowrap">{sell_comment}</td>
                </tr>
            )
        });

        let sumCarCostWithComma = NumberUtil.addCommas(sumCarCost)
        let sumRealSellPriceWithComma = NumberUtil.addCommas(sumRealSellPrice)

        let excelTableRow = {
            A: '',
            B: '',
            C: '',
            D: '',
            E: '',
            F: '',
            G: '',
            H: 'รวม',
            I: sumCarCostWithComma,
            J: '',
            K: sumRealSellPriceWithComma,
            L: '',
            M: ''
        }
        excelTables.push(excelTableRow)

        let sumRow = <tr>
            <td colSpan={8}  className="text-right font-weight-bold" >รวม</td >
            <td className="font-weight-bold text-right" >{sumCarCostWithComma}</td>
            <td className="font-weight-bold text-right" >&nbsp;</td>
            <td className="font-weight-bold text-right" >{sumRealSellPriceWithComma}</td>
            <td className="font-weight-bold text-right" >&nbsp;</td>
            <td className="font-weight-bold text-right" >&nbsp;</td>
        </tr>

        const tableRows = lists.length === 0 ? emptyBody : listRows

        let car_branch_list = this.state.branches.map((branch, index) => {
            return (
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        });

        // let order_list = this.state.orders.map((order, index) => {
        //     return (
        //         <option key={index} value={order.order_id}>{order.order_name}</option>
        //     )
        // });

        // let width_box = 128

        // if (width <= 980){
        //   width_box = 112
        // }

        let month_year_list_option = this.state.month_year_list.map((month_year, index) => (
          <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
        ))

        return (
            <div>
                <Loading isLoading={this.state.isLoading} />

                <div className="card-block" style={styles.overflowY}>
                    <div className="row">

                        <div className="card card-accent-primary">

                            <div className="card-header">
                                <div className="row col-12">

                                        <div className="form-inline col-8">
                                            {/*<strong className="text-title">
                                                บัญชีผู้ทำการค้าของเก่า
                                            </strong>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                            <strong className="text-title">
                                                เลือกเดือน
                                            </strong>
                                            &nbsp;&nbsp;
                                            <div className="ml-3 mr-3">
                                                <select
                                                  className="form-control"
                                                  name="carin_month_year"
                                                  value={this.state.carin_month_year}
                                                  onChange={(e) => {
                                                        let val = e.target.value
                                                        // this.loadCarInDataMonth(val)
                                                      this.setState({
                                                          carin_month_year: val
                                                      })
                                                  }}
                                                >
                                                    {month_year_list_option}
                                                </select>
                                            </div>

                                            <div className="mr-3">
                                                <select className="form-control"
                                                        style={{width : 180}}
                                                        id="branch_id"
                                                        name="branch_id"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                branch_id: e.target.value
                                                            })
                                                        }}
                                                >
                                                    <option value=''> ตามสาขา </option>
                                                    {car_branch_list}
                                                </select>
                                            </div>

                                            <div className="mr-3">
                                              <button type="button"
                                                      style={{width: 80, marginTop: 0}}
                                                      className="btn btn-sm btn-primary"
                                                      onClick={() => {
                                                        let carin_month_year =  this.state.carin_month_year
                                                        this.loadCarInDataMonth(carin_month_year)
                                                      }} > ตกลง
                                              </button>
                                            </div>

                                            {
                                                checkGroupPermission(128, this.props.grouppermissions).printed ? (
                                                    <div>
                                                        <button type="button"
                                                                style={{width: 80, marginTop: 1 }}
                                                                className="btn btn-sm btn-success"
                                                                onClick={() => this.exportCarInSummaryReport(excelTables)}
                                                        > Excel </button>
                                                    </div>
                                                ) :null
                                            }

                                        </div>

                                    <div className="form-inline col-4 text-right">

                                        {
                                            this.state.lists.length === 0 ? '': (
                                                <div className=" text-center pt-2 pb-1 bg-primary" style={{width: 160, marginLeft: 10}}>
                                                    <h5>
                                                        พบ&nbsp; {this.state.lists.length} &nbsp;คัน
                                                    </h5>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="card-block">
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <table className="table table-striped table-responsive">
                                            <thead>
                                            <tr>
                                                <th className="text-center" rowSpan={2}>ลำดับ</th>
                                                <th className="text-center" colSpan={5}>ตำหนิรูปพรรณ</th>
                                                <th className="text-center" rowSpan={2}>ได้รถมาจาก</th>

                                                <th className="text-center" >วัน เดือน ปี</th>

                                                <th className="text-center" rowSpan={2}>ราคาที่ซื้อมา</th>
                                                <th className="text-center" rowSpan={2}>จำหน่ายไปให้ใคร</th>
                                                <th className="text-center" rowSpan={2}>ราคาที่ขาย</th>

                                                <th className="text-center" >วัน เดือน ปี</th>

                                                <th className="text-center" rowSpan={2}>หมายเหตุ</th>

                                            </tr>
                                            <tr>
                                                <th className="text-center text-nowrap"> ชนิดรถ/ยี่ห้อรถ </th>
                                                <th className="text-center text-nowrap"> ลักษณะรถ </th>
                                                <th style={{width: 200}} className="text-center text-nowrap">ทะเบียนรถ</th>
                                                <th className="text-center text-nowrap"> เลขหมายเครื่องยนต์ </th>
                                                <th className="text-center text-nowrap"> เลขหมายตัวถัง </th>
                                                <th className="text-center" >ที่ซื้อมา</th>
                                                <th className="text-center" >ที่ขาย</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tableRows}


                                            {
                                                viewed ? sumRow: null
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}
// visible
// overflow: 'scroll',
const styles = {
    overflowY: {
        overflow: 'scroll',
    }
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
//   token: state.auth.token,
//   isAuthenticated: state.auth.isAuthenticated
// })
const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    type: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated
})

// export default CarInReport;
export default connect(mapStateToProps)(CarMerchantAccount);

