import React, {useEffect, useState} from "react";
import axios from "axios";
import {APIURL} from "../../config/config";
import {Pie} from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);


function CurrentCarPersonVat() {

    const [carPersonVat, setCarPersonVat] = useState([])
    const [head, setHead] = useState([])

    useEffect( () => {

        async function loadCarPersonVats() {
            let results = await axios.get(APIURL+'/report_car/personvat')
            let data = results.data

            setCarPersonVat(data.rows)
            setHead(data.head)
        }
        loadCarPersonVats()
    }, [])

    function genPieChart() {
        const labels = head

        /*const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'สรุปตามประเภทการซื้อ',
                },
            },
        };*/
        //  'rgba(153, 102, 255, 0.2)',
        //  'rgba(255, 159, 64, 0.2)',
        // 'rgba(255, 206, 86, 0.2)',
        //                         'rgba(75, 192, 192, 0.2)',

        // label: '# ประเภทการซื้อ',
        const data = {
            labels: labels,
            datasets: [
                {
                    data: carPersonVat,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.7)',
                        'rgba(54, 162, 235, 0.7)',
                        'rgba(255, 206, 86, 0.7)',
                    ],
                    borderWidth: 1,
                }
            ],
        };

        // options={options}
        return <div style={{minWidth: 450, minHeight: 280}}>
            <Pie  data={data} />
        </div>
    }

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-header">
                    <strong className="text-title">สรุปตามประเภทการซื้อ</strong>
                </div>
                <div className="card-block">
                    <div className="row">
                        <div className="col-2">
                            &nbsp;
                        </div>
                        <div className="col-8">
                            {genPieChart()}
                        </div>
                        <div className="col-2">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentCarPersonVat