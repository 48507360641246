import React from 'react'

function isInArray(value, array) {
   return array.indexOf(value) > -1;
}

const car_field_name_ids = [
   'branch_id',
   'car_status_id',
   'car_brand_id',
   'car_model_id',
   'car_sub_model_id',
   'car_gear_id',
   'car_color_id',
   'car_year_id',
   'province_new_id',

   'car_type_id',
   'car_structure_id',
   'car_hassock_id',
   'car_system_drive_id',
   'car_type_engine_id',

   'insurance_type_id',
   'insurance_company_id',
   'compulsory_insurance_company',

   'liciense_status_id',
   'change_book_status_id',
   'car_adapt_status_id',

   'finance_id',
   'finance_floorplan_id',
    'deposit_registration_id'
]

const car_sell_field_ids = [
   'sale_by_id',
   'car_sell_type_id',
   'bank_finance_id',
   'insurance_company_id',
   'insurance_type_id',
   'duration_period_id',
   'refund_type',
   'more_detail_id',
   'liciense_status_id',
]

export const DataLogUtil = {
   getIdNameValueCarEdit(fieldName, value, arrayLists) {
      if(arrayLists.length === 0){
         return value
      }

      if (isInArray(fieldName, car_field_name_ids)) {
         try{

            let nameValueField = fieldName.replace("_id", "_name");
            if (fieldName === 'province_new_id') {
               nameValueField = 'province_name'
               fieldName = 'province_id'
            }else if (fieldName === 'compulsory_insurance_company') {
               nameValueField = 'insurance_company_name'
               fieldName = 'insurance_company_id'
            }else if (fieldName === 'finance_id') {
               nameValueField = 'bank_name'
               fieldName = 'bank_id'
            }

            let arr_filter = arrayLists.filter(obj => {
               let val = obj[String(fieldName)]
               if (val === Number(value)) {
                  return obj
               }
            })[0];

            let name = arr_filter[String(nameValueField)]
            return value+':'+name.trim()
         }catch (e) {
            return value+': -'
         }
      }else {
         return value
      }
   },
   getIdNameValueCarSellEdit(fieldName, value, arrayLists) {
      if(arrayLists.length === 0){
         return value
      }

      if (isInArray(fieldName, car_sell_field_ids)) {
         try{
            let nameValueField = fieldName.replace("_id", "_name");
            if (fieldName === 'refund_type') {
               nameValueField = 'refund_type_name'
               fieldName = 'refund_type_id'
               // // 1=คืน, 2=ไม่คืน, 3=บางส่วน
               // if (Number(value) === 1) {
               //    return value+':คืน';
               // }else if (Number(value) === 2) {
               //    return value+':ไม่คืน';
               // }else if (Number(value) === 3) {
               //    return value+':บางส่วน';
               // }
            } else if (fieldName === 'sale_by_id') {
               nameValueField = 'name'
               fieldName = 'employee_id'
            } else if (fieldName === 'bank_finance_id') {
               nameValueField = 'bank_name'
               fieldName = 'bank_id'
            }

            let arr_filter = arrayLists.filter(obj => {
               let val = obj[String(fieldName)]
               if (val === Number(value)) {
                  return obj
               }
            })[0];

            let name = arr_filter[String(nameValueField)];
            return value+':'+name.trim();
         }catch (e) {
            return value+': -'
         }
      } else {
         return value
      }
   }
}
