import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {connect} from 'react-redux';
// import MaskedInput  from 'react-maskedinput'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import { Modal, ModalBody } from 'reactstrap'
import {AlertSuccess, AlertWarning} from '../../Alert/Alert'
import {format} from "date-fns";
import {ThaiDatePicker} from "thaidatepicker-react";
import SelectSearch from "react-select-search";
import BDatePicker from '../../BDatePicker'
import Switch from 'react-switch'
import {checkFormControls} from "../../../services/grouppermission";
moment.locale('th');


class CarExpenseFormEdit extends Component {

    constructor (props) {
        super(props)

        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            expense:'',
            branch:[],
            expense_type:[],
            income_expense_item:[],

            // startDateCarExpense: new Date() ,//moment().format('YYYY-MM-DD'),
            // startDateExpense: new Date(this.props.expense.expense_date), // this.props.expense.expense_date
            expense_date: format(new Date(this.props.expense.expense_date), 'yyyy-MM-dd'), // this.props.expense.expense_date

            w_expense_image_file: false,
            user: {},
            car: '',
            //selectedItem:'',
          expense_id: this.props.expense.expense_id,
          expense_name: this.props.expense.expense_name,
          expense_type_id: this.props.expense.expense_type_id,
          branch_id: this.props.expense.branch_id,
          expense_amount: this.props.expense.expense_amount,
          expense_amount_old: this.props.expense.expense_amount,
          income_expense_item_id: this.props.expense.income_expense_item_id,
          select_income_expense_item_id: this.props.expense.income_expense_item_id,
          expense_amount_vat: this.props.expense.expense_amount_vat,
          pay_by: this.props.expense.pay_by,
          //
          width: 0,
          height: 0,
          invoice_no: this.props.expense.invoice_no,
          buy_from_company: this.props.expense.buy_from_company,
          include_vat: this.props.expense.include_vat,
          is_negative: false,
            cost_types: [],
            cost_type_id: this.props.expense.cost_type_id,
            pay_method: this.props.expense.pay_method,
        }

        // this.handleDateCarExpense = this.handleDateCarExpense.bind(this)
        // this.convertBBtoCC = this.convertBBtoCC.bind(this)
        // this.onMaskedDateCarExpense = this.onMaskedDateCarExpense.bind(this)
        // this.handleDateExpense = this.handleDateExpense.bind(this)

        this.handleCarExpenseNameChange = this.handleCarExpenseNameChange.bind(this)
        this.handleOnSubmitCarExpenseEdit = this.handleOnSubmitCarExpenseEdit.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
        this.handleInvoiceNoChange = this.handleInvoiceNoChange.bind(this);
        this.handleBuyFromCompanyChange = this.handleBuyFromCompanyChange.bind(this);
        this.handleOnChangeNoSet = this.handleOnChangeNoSet.bind(this);
        this.loadCostTypeData = this.loadCostTypeData.bind(this)
        this.onChangePayMethod = this.onChangePayMethod.bind(this)
    }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

    loadCostTypeData () {
        axios.get(APIURL + '/cost_type')
            .then(res => {
                this.setState({cost_types: res.data})
            }).catch(error => {
            this.setState({cost_types: []})
        })
    }

    onChangePayMethod(e) {
        this.setState({
            pay_method: e.currentTarget.value,
        })
    }

  handleOnSubmitCarExpenseEdit(event) {
        event.preventDefault()

        // let that = this ;
        let car_id = this.props.car_id
        let expense_id = this.state.expense_id
        let expense_amount_old = this.state.expense_amount_old
        // let expense_date = this.state.startDateCarExpense
        // let expense_date = moment(this.state.startDateExpense).format('YYYY-MM-DD')
        let expense_date = format(new Date(this.state.expense_date), 'yyyy-MM-dd')
        let branch_id = event.target.branch_id.value
      // select_income_expense_item_id
        // let income_expense_item_id = event.target.income_expense_item_id.value
        let income_expense_item_id = this.state.select_income_expense_item_id
        let expense_name = event.target.expense_name.value
        let expense_amount = event.target.expense_amount.value
      //
        let expense_amount_vat = this.state.expense_amount_vat
        let pay_by = this.state.pay_by
        let include_vat = this.state.include_vat
        let cost_type_id = this.state.cost_type_id
        let pay_method = this.state.pay_method

        let invoice_no = event.target.invoice_no.value
        let buy_from_company = event.target.buy_from_company.value
        //let expense_image_file = this.state.expense_image_file
        //let expense_image_file = event.target.expense_image_file.value

        let expense_type_id = this.props.expense_type_id

        if(expense_amount===''){
            AlertWarning('กรุณากรอกจำนวนเงิน')
            this.refs.expense_amount.focus()
            return ;
        }

        if(income_expense_item_id===''){
            AlertWarning('กรุณาเลือกหมวดหมู่ค่าใช้จ่าย')
            this.refs.income_expense_item_id.focus()
            return ;
        }

        if(branch_id===''){
            AlertWarning('กรุณาเลือกสาขา')
            this.refs.branch_id.focus()
            return ;
        }

      if(event.target.expense_image_file.files.length === 0) { // nofile
        let data = {
          expense_date: expense_date,
          branch_id: branch_id,
          car_id: car_id,
          income_expense_item_id: income_expense_item_id,
          expense_name: expense_name,
          expense_amount: expense_amount,
          // expense_type_id: '1', //ของรถ
          expense_type_id: expense_type_id,
          user_updated: this.state.user.user,
          expense_id: expense_id,
          expense_amount_old: expense_amount_old,
            expense_amount_vat: expense_amount_vat,
            pay_by: pay_by,
            invoice_no: invoice_no,
            buy_from_company: buy_from_company,
            include_vat: include_vat,
            cost_type_id: cost_type_id,
            pay_method: pay_method
        }

        axios.post(APIURL + '/expense/car/edit', data)
          .then(res => {
            if (res.status === 200) {
              AlertSuccess('แก้ไขข้อมูลสำเร็จ');
              setTimeout(() => {
                this.props.onToggle();
                this.props.onReload();
              }, 1500)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }

    }

    handleOnChangeNoSet() {

    }

    handleCarExpenseNameChange(){
        let expense_name = this.refs.expense_name.value
        this.setState({
            expense_name : expense_name
        });
    }

    handleInvoiceNoChange(){
        let invoice_no = this.refs.invoice_no.value
        this.setState({
            invoice_no : invoice_no
        });
    }

    handleBuyFromCompanyChange(){
        let buy_from_company = this.refs.buy_from_company.value
        this.setState({
            buy_from_company : buy_from_company
        });
    }

    loadData () {
        axios.get(APIURL + '/expense/')
            .then(res => {
                //alert("res"+res);
                this.setState({lists: res.data, })
            }).catch(error => {
            //alert("error"+error.message);
            this.setState({lists: []})
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadExpenseTypeData () {
        axios.get(APIURL + '/expense_type/')
            .then(res => {
                this.setState({expense_type: res.data})
            }).catch(error => {
            this.setState({expense_type: []})
        })
    }

    loadIncomeExpenseItemData () {
        // axios.get(APIURL + '/income_expense_item/')
        axios.get(APIURL + '/income_expense_item/get/expense')
            .then(res => {
                this.setState({income_expense_item: res.data}, () => {
                    let income_expense_item = this.state.income_expense_item
                    let income_expense_item_id = this.state.income_expense_item_id
                    let income_expense = income_expense_item.filter(item => item.income_expense_item_id === income_expense_item_id)
                    let is_negative = false

                    if(income_expense.length === 1) {
                        let income_expense_item_name = income_expense[0].income_expense_item_name
                        if (income_expense_item_name.trim() === 'ค่า หัก ณ ที่จ่าย') {
                            is_negative = true
                        }
                    }

                    this.setState({
                        is_negative: is_negative
                    })

                })
            }).catch(error => {
            this.setState({income_expense_item: []})
        })
    }

    componentDidMount() {

      this.setState({
        user: JSON.parse(this.props.user)
      });

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);

      this.loadData()
      this.loadBranchData()
      this.loadExpenseTypeData()
      this.loadIncomeExpenseItemData()
      this.loadCostTypeData()


    }

    componentWillMount () {

    }

    render(){

        const {
            branch,
            cost_types,
            width
        } = this.state;
        const form_controls = this.props.form_controls

        // const { history,user } = this.props
        let branch_list = branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        /*let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
            return(
                <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
            )
        })*/

        let cost_type_list = cost_types.map((cost_type,index)=>{
            return(
                <option key={index} value={cost_type.cost_type_id}>{cost_type.cost_type_name}</option>
            )
        })

        return (
          <Modal isOpen={this.props.isOpen}
                 toggle={this.props.onToggle}
                 className={'modal-lg ' + this.props.className}
                 style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
            <ModalBody>
              <div className="card card-accent-warning">
                <form action="" method="post" onSubmit={this.handleOnSubmitCarExpenseEdit}>
                    <div className="card-header">
                        <strong className="text-title">แก้ไขข้อมูลค่าใช้จ่าย</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        ref="branch_id"
                                        value={this.state.branch_id}
                                        onChange={(e) => {
                                            this.setState({
                                                branch_id: Number(e.target.value)
                                            })
                                        }}
                                >
                                    {branch_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                {/*
                                <label htmlFor="expense_date_text">วันที่ใช้จ่าย</label><br/>
                                 <MaskedInput
                                    mask="11-11-1111"
                                    name="expense_date_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateCarExpense}
                                    className="form-control"
                                />*/}
                                {/*<DatePicker
                                    type="text"
                                    className="form-control"
                                    name="expense_date_text"
                                    placeholder=""
                                    selected={this.state.startDateExpense}
                                    onChange={this.handleDateExpense}
                                    dateFormat="yyyy-MM-dd"
                                />*/}

                                {/*<ThaiDatePicker
                                    id="expense_date"
                                    name="expense_date"
                                    value={this.state.expense_date}
                                    onChange={(christDate, buddhistDate)=>{
                                        this.setState({expense_date : christDate})
                                    }}
                                />*/}

                              <BDatePicker
                                isDateFill={this.props.company_setting.is_date_fill}
                                name={'expense_date'}
                                value={this.state.expense_date}
                                label={'วันที่ใช้จ่าย '}
                                onUpdateDate={(buddhist_date, christ_date) => {
                                  this.setState({
                                    expense_date: christ_date
                                  })
                                }}
                              />

                            </div>

                            <div
                                className={checkFormControls('cost_type_id', form_controls) ? "form-group col-sm-3" :  "form-group col-sm-6" }
                            >
                                <label htmlFor="income_expense_item">หมวดค่าใช้จ่าย</label>
                                {/*<select
                                    className="form-control"
                                    id="income_expense_item_id"
                                    ref="income_expense_item_id"
                                    value={this.state.income_expense_item_id}
                                    onChange={(e) => {
                                      this.setState({
                                        income_expense_item_id: Number(e.target.value)
                                      });
                                    }}
                                >
                                    {income_expense_item_list}
                                </select>*/}

                                <SelectSearch
                                    ref="income_expense_item_id"
                                    options={this.state.income_expense_item}
                                    value={this.state.select_income_expense_item_id}
                                    search={true}
                                    name="income_expense_item_id"
                                    placeholder="เลือกหมวดค่าใช้จ่าย"
                                    onChange={(val) => {
                                        let income_expense_item = this.state.income_expense_item
                                        let income_expense = income_expense_item.filter(item => item.income_expense_item_id === val)

                                        let is_negative = false

                                        if(income_expense.length === 1) {
                                            let income_expense_item_name = income_expense[0].income_expense_item_name

                                            if (income_expense_item_name.trim() === 'ค่า หัก ณ ที่จ่าย') {
                                                is_negative = true
                                            }
                                        }

                                        this.setState({
                                            select_income_expense_item_id: val,
                                            is_negative: is_negative
                                        })
                                    }}
                                />

                            </div>

                            <div
                                className={checkFormControls('cost_type_id', form_controls) ? "form-group col-sm-3" : 'hidden'}
                            >
                                <label htmlFor="cost_type_id">ประเภทต้นทุน</label>
                                <select
                                    className="form-control"
                                    name="cost_type_id"
                                    value={this.state.cost_type_id}
                                    onChange={(e) => this.setState({cost_type_id: e.target.value})}
                                >
                                    {cost_type_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_name">รายละเอียดค่าใช้จ่าย (พิมพ์เพิ่มเติมได้)</label>
                                <input type="text"
                                       className="form-control"
                                       id="expense_name"
                                       ref="expense_name"
                                       value={this.state.expense_name}
                                       placeholder=""
                                       onChange={this.handleCarExpenseNameChange}
                                />
                            </div>

                            <div
                                className={checkFormControls('invoice_no', form_controls) ? "form-group col-sm-6" : 'hidden'}
                            >
                                <label htmlFor="invoice_no">เลขที่บิล/ใบสั่งซื้อ</label>
                                <input type="text"
                                       className="form-control"
                                       id="invoice_no"
                                       name="invoice_no"
                                       ref="invoice_no"
                                       value={this.state.invoice_no}
                                       placeholder=""
                                       onChange={this.handleInvoiceNoChange}
                                />
                            </div>

                            <div
                                className={checkFormControls('buy_from_company', form_controls) ? "form-group col-sm-6" : 'hidden'}
                            >
                                <label htmlFor="buy_from_company">บริษัทคู่ค้า</label>
                                <input type="text"
                                       className="form-control"
                                       id="buy_from_company"
                                       name="buy_from_company"
                                       ref="buy_from_company"
                                       value={this.state.buy_from_company}
                                       placeholder=""
                                       onChange={this.handleBuyFromCompanyChange}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="expense_amount" className="form-inline">
                                  ยอดค่าใช้จ่าย &nbsp;
                                  {
                                    this.state.include_vat === 1 ? '*รวม vat': '*ไม่รวม vat'
                                  }
                                  &nbsp;
                                  <div style={{marginBottom: -2}}>
                                    <Switch height = {20} width ={48}
                                            onChange={(checked) => {
                                              if (checked === false) {
                                                this.setState({include_vat: 0})
                                              } else {
                                                this.setState({include_vat: 1})
                                              }
                                            }}
                                            checked={this.state.include_vat === 1}
                                    />
                                  </div>
                                </label>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="expense_amount"
                                    placeholder=""
                                    ref="expense_amount"
                                    value={this.state.expense_amount}
                                    onChange={()=>{}}
                                />
                                <NumberFormat
                                    className={this.state.is_negative ? "form-control text-danger" : "form-control" }
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="ค่าใช้จ่าย"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={this.state.is_negative}
                                    value={this.state.expense_amount}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({expense_amount: value})
                                    }}
                                />
                            </div>

                            <div
                                className={checkFormControls('expense_amount_vat', form_controls) ? "form-group col-sm-3" : 'hidden'}
                            >
                                <label htmlFor="expense_amount_vat">VAT(ถ้ามี) &nbsp;</label>
                                <button type="button" style={{height: 25}} onClick={() => {
                                  if (this.state.include_vat === 0) {
                                    let expense_amount = this.state.expense_amount === '' ? 0 : Number(this.state.expense_amount);
                                    let vat = expense_amount * (this.props.company_setting.vat/100);
                                    let vat_fix = parseFloat(String(vat)).toFixed(2);

                                    this.setState({
                                        expense_amount_vat : vat_fix
                                    });

                                  } else if (this.state.include_vat === 1) {

                                    // company_setting
                                    // มูลค่า VAT = ราคารวม VAT x 7/107. ตัวอย่างการ
                                    let expense_amount = this.state.expense_amount === '' ? 0 : Number(this.state.expense_amount);
                                    let vatNumber = Number(this.props.company_setting.vat) // = 7
                                    let vatDivide = 100 + vatNumber // 107
                                    let vatAmount = expense_amount * (vatNumber / vatDivide)
                                    let vat_fix = parseFloat(String(vatAmount)).toFixed(2);

                                    this.setState({
                                      expense_amount_vat: vat_fix
                                    });
                                  }

                                }} >
                                  {this.state.include_vat === 1 ? 'ถอด VAT' : 'คำนวณ VAT'}
                                </button>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="expense_amount_vat"
                                    placeholder=""
                                    ref="expense_amount_vat"
                                    value={this.state.expense_amount_vat}
                                    onChange={this.handleOnChangeNoSet}
                                />
                                {/*<button type="button" onClick={() => {
                                    let expense_amount = this.state.expense_amount === '' ? 0 : Number(this.state.expense_amount);
                                    let vat = expense_amount * this.state.vatPercent;
                                    let vat_fix = parseFloat(String(vat)).toFixed(2);

                                    this.setState({
                                        expense_amount_vat : vat_fix
                                    });

                                }} >คำนวณ</button>*/}
                                <NumberFormat
                                    className="form-control"
                                    style={{marginTop: -3}}
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="VAT ค่าใช้จ่าย"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.expense_amount_vat}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({expense_amount_vat: value})
                                    }}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="pay_by">ผู้เบิก</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="pay_by"
                                    placeholder=""
                                    value={this.state.pay_by}
                                    onChange={(e)=>{
                                        this.setState({pay_by: e.target.value })
                                    }}
                                />
                            </div>

                            <div className="form-group col-sm-3">
                                <label className="">จ่ายโดย </label>
                                <br/>
                                <input type="radio"
                                       name="pay_method"
                                       value="เงินสด"
                                       className="btn btn-primary"
                                       defaultChecked={this.state.pay_method === 'เงินสด'}
                                       onChange={this.onChangePayMethod}
                                /> &nbsp;<span style={{marginTop: 0}}>เงินสด</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio"
                                       name="pay_method"
                                       value="เงินโอน"
                                       className="btn btn-primary"
                                       defaultChecked={this.state.pay_method === 'เงินโอน'}
                                       onChange={this.onChangePayMethod}
                                /> &nbsp;<span style={{marginTop: 0}}>เงินโอน</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio"
                                       name="pay_method"
                                       value="บัตรเครดิต"
                                       className="btn btn-primary"
                                       defaultChecked={this.state.pay_method === 'บัตรเครดิต'}
                                       onChange={this.onChangePayMethod}
                                /> &nbsp;<span style={{marginTop: 0}}>บัตรเครดิต</span>
                            </div>

                            <div className="form-group col-md-12 hidden">
                                <label htmlFor="expense_image_file">เอกสารแนบ </label>
                                <input type="file"
                                       id="expense_image_file"
                                       name="expense_image_file"
                                       accept="image/*"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_expense_image_file: false
                                               });
                                           } else {
                                               //target.reset();
                                               this.setState({
                                                   w_expense_image_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_expense_image_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

                            </div>

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                    onClick={this.props.onToggle}
                                    className="btn btn-sm btn-danger mr-2">
                                <i className="fa fa-refresh"/> ยกเลิก
                            </button>
                        <button type="submit"
                                className="btn btn-sm btn-warning">
                            <i className="fa fa-pencil"/> แก้ไข
                        </button>
                        {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
                    </div>

                </form>
            </div>
            </ModalBody>
          </Modal>
        )

    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarExpenseFormEdit);
