
import React, { Component } from 'react'
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import {AlertWarning} from "../Alert/Alert";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import {APIURL} from "../../config/config";
import {format} from "date-fns";
import {NumberUtil} from "../../utils/number-util";
import {DateUtil} from "../../utils/dateUtil";
import {
    titleStyle,
    contentStyle,
    contentBoldStyle,
    border
} from "../../utils/exceljsStyles";
import Loading from "../Loading";
import {checkGroupPermission} from "../../services/grouppermission";

const animatedComponents = makeAnimated();

// วิธีที่ 1 รายรับรวม
// 1. รายรับอื้นๆ ของเต็นท์
// 2. รับชำระหนี้
// 3. ประวัติการรับชำระ
// 4. ใบกำกับไฟแนนซ์
// 5. ใบกำกับค่าคอม

// *** บางเต็นท์ ไม่ได้ใช้ ข้อ 3, 4
// ---------------- //
// วิธีที่ 2 รายรับรวม
// 1.รายรับอื้นๆ ของเต็นท์ รายได้ => ยอดที่กรอก
// 2.รับชำระหนี้    รายได้ => ยอดชำระ
// 3.รายงานการขาย  รายได้ => กำไร

class ReceiptAll extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            date_start: new Date(),
            date_end: new Date(),
            branches: [],
            branches_select: null,
            incomes: []
        }
        this.handleDateStart = this.handleDateStart.bind(this);
        this.handleDateEnd = this.handleDateEnd.bind(this);
        this.loadReceiptAll = this.loadReceiptAll.bind(this);
        this.loadBranchData = this.loadBranchData.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
    }

    componentDidMount() {
       // let grouppermissions = this.props.grouppermissions
       // let user = this.props.user

        this.loadBranchData()
        this.loadReceiptAll(true)
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    loadReceiptAll(is_first_load) {
        let start = null
        let end = null

        if (is_first_load === true){
            let today = new Date()
            start = format(today, 'yyyy-MM-01')
            end = format(today, 'yyyy-MM-dd')
            this.setState({
                date_start: new Date(start),
                date_end: new Date(end),
            })
        } else {
            start =  moment(this.state.date_start).format('YYYY-MM-DD')
            end =  moment(this.state.date_end).format('YYYY-MM-DD')
        }

        if(this.state.date_start > this.state.date_end){
            AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let branches_select = this.state.branches_select

        let data = {
            start_date: start,
            end_date: end,
            branches_select
        }

        axios.post(APIURL + '/receipts/totalincome', data)
            .then(res => {
                let incomes = res.data
                this.setState({incomes: incomes})
            }).catch(error => {
            this.setState({incomes: []})
        })

    }

    exportExcel() {
        const { incomes } = this.state;

        this.setState({
            isLoading: true
        });

        let excelData = incomes.map((income, index) => {
            let order = index +1
            const income_date_thai = DateUtil.convertLongDateThaiNew(income.income_date)
            const income_amount = NumberUtil.addCommas(income.income_amount)
            const income_vat = NumberUtil.addCommas(income.income_vat)
            return {
                order: order,
                income_date: income_date_thai,
                branch_name : income.branch_name,
                income_item_name : income.income_item_name,
                income_detail: income.income_detail,
                income_amount: income_amount,
                income_vat: income_vat,
                income_user: income.income_user
            }
        });

        const income_amount_sum = incomes.reduce((acc, income) => acc+income.income_amount, 0)
        const income_vat_sum = incomes.reduce((acc, income) => acc+income.income_vat, 0)
        const income_sum = {
            order: '',
            income_date: '',
            branch_name : '',
            income_item_name : '',
            income_detail: 'รวม',
            income_amount: NumberUtil.addCommas(income_amount_sum),
            income_vat: NumberUtil.addCommas(income_vat_sum),
            income_user: ''
        };
        excelData.push(income_sum);

        const columns = [
            { header: "ลำดับ", key: "order", width: 6,},
            { header: "วันที่รับเงิน", key: "income_date", width: 16,},
            { header: "สาขา", key: "branch_name", width: 16,},
            { header: "หมวดรายรับ", key: "income_item_name", width: 16,},
            { header: "รายละเอียด", key: "income_detail", width: 20,},
            { header: "ยอดรายรับ", key: "income_amount", width: 16,},
            { header: "VAT", key: "income_vat", width: 16,},
            { header: "ผู้บันทึก", key: "income_user", width: 16,},
        ];

        const ExcelJS = require("exceljs/dist/exceljs");
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("รายรับรวม");

        sheet.columns = columns

        let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
        let rows = [
            ['รายงานรายรับรวม'],
            ['พิมพ์วันที่ : '+curr],
        ];
        sheet.insertRows(1, rows);

        const promise = Promise.all(
            excelData.map(async (income, index) => {
                let rowNumber = index + 3;
                sheet.addRow(income)
                sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    cell.border = border;
                    if (rowNumber === 3) {
                        cell.font = contentBoldStyle
                    }else{
                        cell.font = contentStyle
                    }
                });

                if((excelData.length-1) === index) {
                    let lastRow = rowNumber+1
                    sheet.getRow(lastRow).eachCell((cell, colNumber ) =>  {
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                        cell.border = border;
                        cell.font = contentBoldStyle;
                    });
                }
        }));

        promise.then(() => {
            sheet.getRow(1).font = titleStyle
            sheet.getRow(2).font = contentStyle
            // sheet.getRow(3).font = contentBoldStyle
            // sheet.getRow(3).border = border
            // sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

            workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                let curr = format(new Date(), 'yyyyMMdd_HHmmss')
                const fileName = 'รายงานรายรับรวม_export_'+curr+'.xlsx'

                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });

            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            } , 1200)

        })
    }

    render () {

        const { incomes } = this.state
        let grouppermissions = this.props.grouppermissions

        const emptyRow = <tr><td colSpan={7} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

        // income_detail	income_amount	income_vat	income_user
        const incomeRows = incomes.map((income, index) => {
            // let income_date = moment(income.income_date).locale('th').format('LL')
            // let income_date = new Date(income.income_date)
            const income_date_thai = DateUtil.convertLongDateThaiNew(income.income_date)
            const income_amount = NumberUtil.addCommas(income.income_amount)
            const income_vat = NumberUtil.addCommas(income.income_vat)

            return (
              <tr key={index}>
                  <td >{index+1}</td>
                  <td >{income_date_thai}</td>
                  <td >{income.branch_name}</td>
                  <td >{income.income_item_name}</td>
                  <td >{income.income_detail}</td>
                  <td >{income_amount}</td>
                  <td >{income_vat}</td>
                  <td >{income.income_user}</td>
              </tr>
            );
        });

        const income_amount_sum = incomes.reduce((acc, income) => acc+income.income_amount, 0)
        const income_vat_sum = incomes.reduce((acc, income) => acc+income.income_vat, 0)

        const incomeSumList = <tr>
            <td colSpan={5} className="font-weight-bold text-right"> รวม </td>
            <td className="font-weight-bold">{NumberUtil.addCommas(income_amount_sum)}</td>
            <td className="font-weight-bold">{NumberUtil.addCommas(income_vat_sum)}</td>
            <td>&nbsp;</td>
        </tr>

        const incomeList = incomes.length === 0 ? emptyRow : incomeRows

        // หมวดรายรับ	รายละเอียด	ยอดรายรับ	VAT 	ผู้บันทึก
        return (
            <div>
                <Loading isLoading={this.state.isLoading} />
                <div  >

                    <div className="row" style={styles.overflowY}>
                        <div className="col-sm-12 col-md-12">
                        <div className="card card-accent-primary">
                            <div className="card-header">
                                <strong className="text-title">
                                    รายงานรายรับรวม
                                </strong>
                            </div>
                            <div className="card-block">
                                <div className="row mt-1">
                                    <div className="mt-1 ml-3">
                                        จาก
                                    </div>
                                    <div className="col-1" style={{width: 180}}>
                                        <DatePicker
                                            selected={this.state.date_start}
                                            onChange={this.handleDateStart}
                                            dateFormat="yyyy-MM-dd"
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="mt-1 ml-2">
                                        ถึง
                                    </div>
                                    <div className="col-1" style={{width: 180}}>
                                        <DatePicker
                                            selected={this.state.date_end}
                                            onChange={this.handleDateEnd}
                                            dateFormat="yyyy-MM-dd"
                                            type="text"
                                            className="form-control d-block"
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group ml-2 mr-2" style={{width: 280}}>
                                        <Select
                                            styles={customStyles}
                                            placeholder="เลือกสาขา"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={[]}
                                            isMulti
                                            options={this.state.branches}
                                            onChange={(val) => {
                                                this.setState({
                                                    branches_select: val,
                                                })
                                            }}
                                        />

                                    </div>

                                    <div className="form-group ml-2 mr-3" style={{width: 170}}>
                                        &nbsp;
                                    </div>
                                    <div className="d-flex mr-2">
                                        <button type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.loadReceiptAll(false)} >
                                            ตกลง
                                        </button>

                                        {
                                            checkGroupPermission(1103, grouppermissions).printed ?
                                                <button
                                                    className="btn btn-sm btn-success mr-1 ml-2"
                                                    onClick={() => this.exportExcel()}
                                                >
                                                    <i className="icon-search"/> Excel
                                                </button>
                                             : null
                                        }

                                    </div>
                                </div>

                                <div className="row">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">#</th>
                                                <th className="text-center">วันที่รับเงิน</th>
                                                <th className="text-center">สาขา</th>
                                                <th className="text-center">หมวดรายรับ</th>
                                                <th className="text-center">รายละเอียด</th>
                                                <th className="text-center">ยอดรายรับ</th>
                                                <th className="text-center">VAT</th>
                                                <th className="text-center">ผู้บันทึก</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {incomeList}
                                        {incomeSumList}
                                        </tbody>
                                    </table>
                                    ***ยอดรายรับ เป็นยอดไม่รวม vat
                                </div>


                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    overflowY: {
        overflow: 'scroll',
    }
}

const customStyles = {
    control: (provided) => ({ // class attribute : class=" css-i32vvf-control"
        ...provided,
        background: 'transparent',
        display: 'flex',
        flexWrap: 'nowrap',
        borderRadius: 0,
        height: 5
    }),
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(ReceiptAll);