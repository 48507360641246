import React, { Component } from 'react';
import axios from 'axios';
import {APIURL, PORT} from '../../../config/config';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import ReactQuill from 'react-quill'
import {AlertWarning} from "../../Alert/Alert";
import {checkFormControls} from "../../../services/grouppermission";
import SelectSearch from "react-select-search";
import { DataLogUtil } from '../../../utils/dataLogUtil'
import { addDataLog } from '../../../services/dataLogService'
import BDatePicker from '../../BDatePicker'
// import OutstandingBalanceComponent from '../OutstandingBalanceComponent'
// import moment from 'moment'

const port = Number(window.env.PORT);

class SalesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            lists: [],
            car_sell_type:[],
            insurance_company:[],
            insurance_type:[],
            count_period:[],
            duration_period:[],
            customer_list: [],
            car_sell_list_pay:[],
            car_status:[],
            sales_type:[],
            car: '',
            bank:[],
            car_sell: '',
            province:[],
            customer: {},
            load_data: true,
            danger: false,
            more_detail:[],
            employees:[],
            liciense_status : [],
            car_id: this.props.car_sell.car_id ,
            car_sell_id: this.props.car_sell.car_sell_id,
            car_brand_name: this.props.car_sell.car_brand_name,
            car_model_name: this.props.car_sell.car_model_name,
            car_sub_model_name : this.props.car_sell.car_sub_model_name,
            car_year_name : this.props.car_sell.car_year_name,
            car_gear_name : this.props.car_sell.car_gear_name,
            car_color_name : this.props.car_sell.car_color_name,
            car_miles : this.props.car_sell.car_miles,
            car_license_plate_new : this.props.car_sell.car_license_plate_new,
            province_name_new : this.props.car_sell.province_name_new,
            customer_name : this.props.car_sell.customer_name,
            customer_lastname : this.props.car_sell.customer_lastname,
            customer_card_id : this.props.car_sell.customer_card_id,
            customer_mobile : this.props.car_sell.customer_mobile,
            customer_address : this.props.car_sell.customer_address,
            car_sell_type_id: String(this.props.car_sell.car_sell_type_id),
            insurance_company_id: this.props.car_sell.insurance_company_id,
            insurance_type_id: this.props.car_sell.insurance_type_id,
            count_period_id: this.props.car_sell.count_period_id === null ? 0 : this.props.car_sell.count_period_id,
            duration_period_id: this.props.car_sell.duration_period_id,
            bank_finance_id: this.props.car_sell.bank_finance_id,
            car_status_id: this.props.car_sell.car_status_id,
            real_sell_price: this.props.car_sell.real_sell_price,
            normal_sell_price: this.props.car_sell.normal_sell_price,
            car_sell_discount: this.props.car_sell.car_sell_discount,
            rate_finance: this.props.car_sell.rate_finance,
            insurance_pa: this.props.car_sell.insurance_pa,
            tax_rate_finance: this.props.car_sell.tax_rate_finance,
            total_rate_finance: this.props.car_sell.total_rate_finance,
            sum_down: this.props.car_sell.sum_down,
            vat_down: this.props.car_sell.vat_down,
            insurance_price: this.props.car_sell.insurance_price,
            cost_finance: this.props.car_sell.cost_finance,
            cost_transfer: this.props.car_sell.cost_transfer,
            cost_period_month: this.props.car_sell.cost_period_month,
            total_cost_period_call: this.props.car_sell.total_cost_period_call,
            total_outof_car: this.props.car_sell.total_outof_car,
            balance_debt: this.props.car_sell.balance_debt <= 0 ? 0 : this.props.car_sell.balance_debt,
            date_promise: this.props.car_sell.date_promise!==null && this.props.car_sell.date_promise!=='0000-00-00' ?  this.props.car_sell.date_promise : null ,
            insurance_buy: this.props.car_sell.insurance_buy,
            promotion_name: this.props.car_sell.promotion_name,
            car_over_sales: this.props.car_sell.car_over_sales,
            car_excess_amount: this.props.car_sell.car_excess_amount,
            customer_cash: this.props.car_sell.customer_cash,
            car_external_commission: this.props.car_sell.car_external_commission,
          monthly_installment : this.props.car_sell.monthly_installment,
          total_installment : this.props.car_sell.total_installment,
          first_installment : this.props.car_sell.first_installment === '0000-00-00' || this.props.car_sell.first_installment === null ? null : new Date(this.props.car_sell.first_installment),
          interest_installment : this.props.car_sell.interest_installment,
          car_issue_date : this.props.car_sell.car_issue_date !== '0000-00-00'? new Date(this.props.car_sell.car_issue_date) : null,
          liciense_status_id : this.props.car_sell.liciense_status_id,
          pay_advance_finance : this.props.car_sell.pay_advance_finance,
          sell_discount : this.props.car_sell.sell_discount,
          sell_finance_contract : this.props.car_sell.sell_finance_contract === null ? '' : this.props.car_sell.sell_finance_contract,
          sell_comment : this.props.car_sell.sell_comment === null ? '' : this.props.car_sell.sell_comment,
          //
          car_transfer_date : this.props.car_sell.car_transfer_date === '0000-00-00' || this.props.car_sell.car_transfer_date === null ? null : new Date(this.props.car_sell.car_transfer_date),
          finance_payment_date : this.props.car_sell.finance_payment_date === '0000-00-00' || this.props.car_sell.finance_payment_date === null ? null : new Date(this.props.car_sell.finance_payment_date),
          startDatePromiseEdit: this.props.car_sell.date_promise!==null && this.props.car_sell.date_promise!=='0000-00-00'?  new Date(this.props.car_sell.date_promise) : null ,//moment(),
            /*promotion_list: [
              {promotion_id: 1, promotion_name: 'ส่วนลด1หมื่น' },
              {promotion_id: 2, promotion_name: '1แถม1' },
              {promotion_id: 3, promotion_name: 'จ่าย2ขับ4' },
              {promotion_id: 4, promotion_name: 'ช่วยค่างวด1ปี' },
              {promotion_id: 5, promotion_name: 'ขับฟรีถึงปีใหม่' },
              {promotion_id: 6, promotion_name: 'ของแถมยกเซ็ต12รายการ' },
              {promotion_id: 7, promotion_name: 'แถมช่วยเหลือฉุกเฉิน smart sure' },
              {promotion_id: 8, promotion_name: 'แถมประกันเครื่องเกียร์ Fixman' }
           ],*/
          promotion_list: [],
          gearbox_warranty_list: [
            {gearbox_warranty_id: 1, gearbox_warranty_name: 'ไม่รับประกันเครื่องเกียร์' },
            {gearbox_warranty_id: 2, gearbox_warranty_name: 'รับประกันเครื่องเกียร์' },
          ],
          gearbox_warranty : this.props.car_sell.gearbox_warranty === null ? '' : this.props.car_sell.gearbox_warranty,
          gearbox_bank_name : this.props.car_sell.gearbox_bank_name === null ? '' : this.props.car_sell.gearbox_bank_name,
          gearbox_amount : this.props.car_sell.gearbox_amount,
          debt_insurance_amount : this.props.car_sell.debt_insurance_amount,
          sum_pay: 0,
          vat: this.props.vat,
          is_vat: this.props.is_vat,
          is_key_vat_down: port === 9043 ? true : false,
          more_detail_id: this.props.car_sell.more_detail_id,
          total_outof_discount: this.props.car_sell.total_outof_discount,
          transfer_fee_estimate: this.props.car_sell.transfer_fee_estimate,
          refund_type: this.props.car_sell.refund_type,
          refund_inpart: this.props.car_sell.refund_inpart,
          sale_by_id: this.props.car_sell.sale_by_id,
          insurance_pa_buy: this.props.car_sell.insurance_pa_buy === '' ||  this.props.car_sell.insurance_pa_buy === null || this.props.car_sell.insurance_pa_buy === 'PA ซื้อเอง' ? 'PA ซื้อเอง' : 'PA แถม',
          cost_sell_act: this.props.car_sell.cost_sell_act
        }

        this.handleDatePromise = this.handleDatePromise.bind(this)
        this.onInsuranceBuyChanged = this.onInsuranceBuyChanged.bind(this)
        this.handleOnSubmitCarSellEdit = this.handleOnSubmitCarSellEdit.bind(this)
        this.onInputChangeCarSellEdit = this.onInputChangeCarSellEdit.bind(this)
        this.handleCarIssueDate = this.handleCarIssueDate.bind(this)
        this.handleFistInstallmentDate = this.handleFistInstallmentDate.bind(this)
        this.handleCarDateTransfer = this.handleCarDateTransfer.bind(this)
        this.handleFinancePaymentDate = this.handleFinancePaymentDate.bind(this)
        this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this)
        this.handleSumDown = this.handleSumDown.bind(this)
        this.onInsurancePABuyChanged = this.onInsurancePABuyChanged.bind(this)
        this.handleAddDataLog = this.handleAddDataLog.bind(this)
        this.getArrayListByKey = this.getArrayListByKey.bind(this)
        // console.log(this.props.car_sell)
    }

    handleDatePromise(date) {
        //alert (date);
        this.setState({
            startDatePromiseEdit: date,
            date_promise : date
        });
    }

  handleCarDateTransfer (date) {
    this.setState({
      car_transfer_date: date
    })
  }

  handleFinancePaymentDate (date) {
    this.setState({
      finance_payment_date: date
    })
  }

    handleOnSubmitCarSellEdit(event){
        event.preventDefault()
        // alert(event.target.customer_name.value);
        // let that = this;
        let car_sell_type_id = event.target.car_sell_type_id.value
        let bank_finance_id = event.target.bank_finance_id.value
        let normal_sell_price = event.target.normal_sell_price.value
        let real_sell_price = event.target.real_sell_price.value
        let car_sell_discount = event.target.car_sell_discount.value
        let rate_finance = event.target.rate_finance.value

        let tax_rate_finance = event.target.tax_rate_finance.value
        let total_rate_finance = event.target.total_rate_finance.value
        // let sum_down = event.target.sum_down.value

        let insurance_company_id = event.target.insurance_company_id.value
        let insurance_type_id = event.target.insurance_type_id.value
        let insurance_price = event.target.insurance_price.value
        //let cost_finance = event.target.cost_finance.value
        let cost_finance = this.state.cost_finance
        let cost_transfer = 0 // event.target.cost_transfer.value
        let cost_period_month= 0 //event.target.cost_period_month.value
        let count_period_id = 0 //event.target.count_period_id.value
        let total_cost_period_call = 0 //event.target.total_cost_period_call.value
        //let duration_period_id = event.target.duration_period_id.value
        let duration_period_id = (car_sell_type_id==='1')? 99 : event.target.duration_period_id.value  // car_sell_type_id===1 // 1.สด 2.ผ่อน
        let total_outof_car = event.target.total_outof_car.value
        let balance_debt  = event.target.balance_debt.value
        let car_status_id = event.target.car_status_id.value
        let date_promise  = this.state.date_promise
        let insurance_buy = event.target.insurance_buy.value
        let promotion_name = event.target.promotion_name.value

        let first_installment = this.state.first_installment
        let car_transfer_date = this.state.car_transfer_date
        let finance_payment_date = this.state.finance_payment_date
        let sum_down = this.state.sum_down

      // return;
      // alert('car_status_id='+car_status_id); return;

      let car_over_sales = Number(event.target.car_over_sales.value)
      let car_excess_amount = Number(event.target.car_excess_amount.value)
      let customer_cash = Number(event.target.customer_cash.value)
      // let car_external_commission = Number(event.target.car_external_commission.value)
      // let insurance_pa = event.target.insurance_pa.value
      let insurance_pa = event.target.insurance_pa.value
      let monthly_installment = event.target.monthly_installment.value
      let total_installment = event.target.total_installment.value
      // let first_installment = event.target.first_installment.value
      let interest_installment = event.target.interest_installment.value
      let car_issue_date = this.state.car_issue_date
      let liciense_status_id = event.target.liciense_status_id.value
       //
      // let car_transfer_date = event.target.car_transfer_date.value
      // let finance_payment_date = event.target.finance_payment_date.value
      let pay_advance_finance = event.target.pay_advance_finance.value
      let sell_discount = event.target.sell_discount.value
      let sell_finance_contract = event.target.sell_finance_contract.value
      let sell_comment = this.state.sell_comment
      //
      let gearbox_warranty = this.state.gearbox_warranty
      let gearbox_bank_name = this.state.gearbox_bank_name
      let gearbox_amount = this.state.gearbox_amount === '' ? 0 : this.state.gearbox_amount
      let debt_insurance_amount = this.state.debt_insurance_amount === '' ? 0 : this.state.debt_insurance_amount

        let total_outof_discount = this.state.total_outof_discount
      let more_detail_id = 0

      let refund_type = event.target.refund_type.value
      let refund_inpart = event.target.refund_inpart.value
      //let sale_by_id = event.target.sale_by_id.value
      let sale_by_id = this.state.sale_by_id
      let insurance_pa_buy = event.target.insurance_pa_buy.value

      let cost_sell_act = this.state.cost_sell_act === '' ? 0 : this.state.cost_sell_act

        if(port === 9046){
            more_detail_id = event.target.more_detail_id.value
        }


        if(car_sell_type_id ===''){
            return ;
        }

        if (sale_by_id === '') {
            AlertWarning('กรุณาเลือกผู้ขาย');
            return
        }

        event.target.car_sell_type_id.value = ""
        event.target.bank_finance_id.value = ""
        event.target.normal_sell_price.value = ""
        event.target.real_sell_price.value = ""
        event.target.car_sell_discount.value = ""
        event.target.rate_finance.value = ""
        event.target.insurance_pa.value = ""
        event.target.tax_rate_finance.value = ""
        event.target.total_rate_finance.value = ""
        event.target.sum_down.value = ""
        event.target.insurance_company_id.value = ""
        event.target.insurance_type_id.value = ""
        event.target.insurance_price.value = ""
        event.target.cost_finance.value = ""
        // event.target.cost_transfer.value = ""
        // event.target.cost_period_month.value = ""
        // event.target.count_period_id.value = ""
        // event.target.total_cost_period_call.value = ""
        event.target.duration_period_id.value = ""
        event.target.total_outof_car.value = ""
        event.target.balance_debt.value   = ""
        event.target.date_promise.value   = ""
        event.target.car_status_id.value  = ""
        event.target.promotion_name.value = ""
        event.target.pay_advance_finance.value = ""
        event.target.sell_discount.value = ""
        event.target.sell_finance_contract.value = ""
        //event.target.sale_by_id.value = ""
        event.target.cost_sell_act.value = ""
        // event.target.car_external_commission.value = ""
        if (port === 9046){
            event.target.more_detail_id.value = ""
        }

        event.target.liciense_status_id.value = ""

       // event.target.more_detail_id.value = ""

        let vat_down = PORT === 9048 ? 0 : this.state.vat_down
        let username = this.props.username
        // user_updated: this.state.user.user,
        let data = {
          car_sell_type_id : car_sell_type_id,
          bank_finance_id : bank_finance_id,
          normal_sell_price : normal_sell_price,
          real_sell_price : real_sell_price,
          car_sell_discount : car_sell_discount,
          rate_finance : rate_finance,
          insurance_pa : insurance_pa,
          tax_rate_finance : tax_rate_finance,
          total_rate_finance : total_rate_finance,
          sum_down : sum_down,
          vat_down: vat_down,
          insurance_company_id : insurance_company_id,
          insurance_type_id : insurance_type_id,
          insurance_price : insurance_price,
          cost_finance : cost_finance,
          cost_transfer : cost_transfer,
          cost_period_month : cost_period_month,
          total_cost_period_call : total_cost_period_call,
          duration_period_id : duration_period_id,
          total_outof_car : total_outof_car,
          balance_debt : balance_debt,
          date_promise : date_promise,
          car_status_id : car_status_id,
          // user_updated: username,
          insurance_buy: insurance_buy,
          promotion_name: promotion_name,
          //
          car_over_sales: car_over_sales, //ยอดขายเกิน
          car_excess_amount: car_excess_amount, // ยอดจัดเกิน
          customer_cash: customer_cash , //เงินคืนลูกค้า
          // car_external_commission: car_external_commission, // ค่านายหน้า ภายนอก
          car_id: this.state.car_id,
          //
          monthly_installment : monthly_installment,
          total_installment : total_installment,
          first_installment : first_installment,
          interest_installment : interest_installment,
          car_issue_date : car_issue_date,
          liciense_status_id : liciense_status_id,
          car_transfer_date: car_transfer_date,
          finance_payment_date: finance_payment_date,
          pay_advance_finance: pay_advance_finance,
          sell_discount: sell_discount,
          sell_finance_contract: sell_finance_contract,
          sell_comment: sell_comment,
          gearbox_warranty: gearbox_warranty,
          gearbox_bank_name: gearbox_bank_name,
          gearbox_amount: gearbox_amount,
          debt_insurance_amount: debt_insurance_amount,
          more_detail_id: more_detail_id,
          total_outof_discount: total_outof_discount,
          refund_type : refund_type,
          refund_inpart : refund_inpart,
          sale_by_id : sale_by_id,
          insurance_pa_buy : insurance_pa_buy,
          cost_sell_act: cost_sell_act
        }

        // menu_id = 104
      // car_sell car_sell_id
      let car_sell_old = this.props.car_sell
      let car_sell_id = this.state.car_sell_id

      let data_log = {
        table_name: 'car_sell',
        ref_id: car_sell_id,
        menu_id: 104,
        form_name: 'แก้ไขข้อมูลการขาย',
        user_created: username
      }
      // console.log(data_log)
      this.handleAddDataLog(data, car_sell_old, data_log)
      data = {...data,
        count_period_id : count_period_id,
        user_updated: username,
      }

      // console.log(data)
      // return

        axios.put(APIURL + '/car_sell/car_sell_edit_detail/'+this.state.car_sell_id, data)
            .then(response => {

                if(response.status === 200) {
                    this.props.onToggle()
                    this.props.onLoadCarSellById()
                }

            })
            .catch(error => {
              console.log("พบข้อผิดพลาด : "+error)
            })
    }

  handleAddDataLog(new_data, old_data, data_log) {
    let form_controls = this.props.form_controls
    let data_log_details = []
    let keys = Object.keys(new_data)

    for(let i =0 ; i < keys.length ; i++) {
      let key = keys[i]
      let new_value = new_data[String(key)];
      let old_value = old_data[String(key)];

      if (old_value === "0000-00-00" && new_value === null) {
        continue
      }

      if (!(new_value == old_value)) {
        let form_control_filter = form_controls.filter(fc=> fc.field_name === key)
        let label_name = form_control_filter.length === 0 ? '-' : form_control_filter[0].label_name

        let old_value2 = DataLogUtil.getIdNameValueCarSellEdit(key, old_value, this.getArrayListByKey(key))
        let new_value2 = DataLogUtil.getIdNameValueCarSellEdit(key, new_value, this.getArrayListByKey(key))

        let detail = {
          field_name: key,
          label_name: label_name,
          old_value: old_value2,
          new_value: new_value2
        }
        data_log_details.push(detail)
      }
    }
    //
    if (data_log_details.length !== 0) {
      let data_log_new = { ...data_log, data_log_details: data_log_details }
      // console.log(data_log_new)
      addDataLog(data_log_new)
    }
  }

  getArrayListByKey(fieldName) {
    if (fieldName === 'sale_by_id') {
      return this.state.employees
    } else if (fieldName === 'car_sell_type_id') {
      return this.state.car_sell_type
    } else if (fieldName === 'bank_finance_id') {
      return this.state.bank
    } else if (fieldName === 'insurance_company_id') {
      return this.state.insurance_company
    } else if (fieldName === 'insurance_type_id') {
      return this.state.insurance_type
    } else if (fieldName === 'duration_period_id') {
      return this.state.duration_period
    } else if (fieldName === 'refund_type') {
      let refund_type = [
        {refund_type_id: 0, refund_type_name: 'ไม่ระบุ'},
        {refund_type_id: 1, refund_type_name: 'คืน'},
        {refund_type_id: 2, refund_type_name: 'ไม่คืน'},
        {refund_type_id: 3, refund_type_name: 'บางส่วน'},
      ]
      return refund_type
    } else if (fieldName === 'more_detail_id') {
      return this.state.more_detail
    } else if (fieldName === 'liciense_status_id') {
      return this.state.liciense_status
    }
    return []
  }

    onInputChangeCarSellEdit(event) {
        event.preventDefault()

        if (event.target.id === 'car_sell_type_id') {
            this.setState({car_sell_type_id: this.refs.car_sell_type_id.val});
        }else if (event.target.id === 'bank_finance_id'){
            this.setState({bank_finance_id: this.refs.bank_finance_id.val})
        }else if (event.target.id === 'normal_sell_price') {
            this.setState({normal_sell_price: this.refs.normal_sell_price.val})
        }else if (event.target.id === 'real_sell_price') {
            this.setState({real_sell_price: this.refs.real_sell_price.val})
        }else if (event.target.id === 'rate_finance') {
            this.setState({rate_finance: this.refs.rate_finance.val})
        }else if (event.target.id === 'insurance_pa') {
            this.setState({insurance_pa: this.refs.insurance_pa.val})
        }else if (event.target.id === 'tax_rate_finance') {
            this.setState({tax_rate_finance: this.refs.tax_rate_finance.val})
        }else if (event.target.id === 'sum_down') {
            this.setState({sum_down: this.refs.sum_down.val})
        }else if (event.target.id === 'insurance_company_id') {
            this.setState({insurance_company_id: this.refs.insurance_company_id.val})
        }else if (event.target.id === 'insurance_type_id') {
            // this.setState({insurance_type_id: this.refs.insurance_type_id.val})
          this.setState({insurance_type_id: event.target.value})
        }else if (event.target.id === 'insurance_price') {
            this.setState({insurance_price: this.refs.insurance_price.val})
        }else if (event.target.id === 'cost_finance') {
            this.setState({cost_finance: this.refs.cost_finance.val})
        }else if (event.target.id === 'cost_transfer') {
            this.setState({cost_transfer: this.refs.cost_transfer.val})
        }else if (event.target.id === 'cost_period_month') {
            this.setState({cost_period_month: this.refs.cost_period_month.val})
        }else if (event.target.id === 'count_period_id') {
            this.setState({count_period_id: this.refs.count_period_id.val})
        }else if (event.target.id === 'duration_period_id') {
            this.setState({duration_period_id: this.refs.duration_period_id.val})
        }else if (event.target.id === 'car_status_id') {
            let car_status_id = Number(event.target.value)
            this.setState({car_status_id: car_status_id})
        }else if (event.target.id === 'balance_debt') {
          this.setState({balance_debt: this.refs.balance_debt.val})
        }else if (event.target.id === 'liciense_status_id') {
            this.setState({liciense_status_id: this.refs.liciense_status_id.val })
        }else if (event.target.id === 'more_detail_id'){
            this.setState({more_detail_id: this.refs.more_detail_id.val})
        }else if (event.target.id === 'sale_by_id'){
            this.setState({sale_by_id: this.refs.sale_by_id.val})
        }else if (event.target.id === 'cost_sell_act') {
            this.setState({cost_sell_act: this.refs.cost_sell_act.val})
        }
    }

   handleOnChangeCarSellEditPrice(){

        let car_sell_type_id = this.state.car_sell_type_id
        if (car_sell_type_id === '1') {
            // ขายเงินสด
            this.setState({
                sum_down: 0,
                vat_down: 0
            }, () => {
                this.handleOnChangeCarPriceOutof();
            });
        } else {

            let real_sell_price = Number(this.state.real_sell_price);
            let normal_sell_price = Number(this.state.normal_sell_price);

            if (real_sell_price === '')
                real_sell_price = 0

            let discount = 0;
            if (normal_sell_price !== '' && normal_sell_price > 0) {
                discount = Number(normal_sell_price) - Number(real_sell_price);
            }

            let sell_discount = this.state.sell_discount
            let rate_finance = this.state.rate_finance

            let sum_down = Number(real_sell_price) - Number(rate_finance) - Number(sell_discount)

            let sum_down_current = this.state.sum_down


            //console.log('sum_down_current=', sum_down_current)
            //console.log('sum_down=', sum_down)
            //console.log(sum_down !== sum_down_current)

            sum_down = sum_down < 0 ? 0 : sum_down


            const vat = Number(this.state.vat);
            let vat_down_str = String(sum_down * (vat / 100.0));
            let vat_down_fix = parseFloat(vat_down_str).toFixed(2);

            let vat_down = sum_down < 0 ? 0 : vat_down_fix
            vat_down = PORT === 9048 ? 0 : vat_down

            if (sum_down !== sum_down_current) {
                this.setState({
                    car_sell_discount: discount,
                    sum_down: sum_down,
                    vat_down: vat_down
                }, () => {
                    this.handleOnChangeCarPriceOutof();
                });

                if (sum_down === 0) {
                    this.setState({
                        sell_discount: 0
                    })
                }

            }
        }
    }

    /*handleOnChangeCarSellEditPrice(){

        let real_sell_price = Number(this.state.real_sell_price);
        let normal_sell_price  = Number(this.state.normal_sell_price);
        let rate_finance = Number(this.state.rate_finance)

        if (real_sell_price === '') {
            real_sell_price = 0
        }

        if (rate_finance === '') {
            rate_finance = 0
        }

        let discount = 0;

        if(normal_sell_price !== '' && normal_sell_price > 0) {
            discount = Number(normal_sell_price) - Number(real_sell_price);
        }

        let real_discount = discount >= 0 ? discount: 0 ;
        let car_over_sales = 0;

        if(real_sell_price > normal_sell_price ) {
            car_over_sales = real_sell_price - normal_sell_price
        }

        this.setState({
            car_sell_discount: real_discount, // ราคาส่วนลด
            car_over_sales: car_over_sales
        })

        let profite_sum_down = 0

        if (real_sell_price !== '' && real_sell_price > 0) {
            profite_sum_down = Number(real_sell_price) - Number(rate_finance)
        }

        let car_excess_amount = 0;// ยอดจัดเกิน
        if(rate_finance > real_sell_price) {
            profite_sum_down = 0
            car_excess_amount = rate_finance - real_sell_price;
        }

        if(rate_finance === 0) {
            profite_sum_down = 0
        }

        if (this.state.car_sell_type_id === '1') { // ซื้อเงินสด
            this.setState({
                sum_down: 0 , // เงินดาวน์
                car_excess_amount: 0,
                vat_down: 0,
            },()=>{
                this.handleOnChangeCarPriceOutof()
            })
        } else {

            let sell_discount = Number(this.state.sell_discount);
            if ( sell_discount > 0) {
                profite_sum_down = profite_sum_down - sell_discount
                // car_excess_amount = car_excess_amount - sell_discount
            }

            if ((profite_sum_down + sell_discount) === car_excess_amount) {
                car_excess_amount = 0
            }

            let is_key_vat_down = this.state.is_key_vat_down

            //console.log('is_key_vat_down=', is_key_vat_down)

            let vat_down = 0
            if (is_key_vat_down) {
                vat_down = Number(this.state.vat_down)
            } else {
                const vat =  Number(this.state.vat)/100
                let vat_down_cal = profite_sum_down * vat
                vat_down = parseFloat(String(vat_down_cal)).toFixed(2)
            }

            vat_down = PORT === 9048 ? 0 : vat_down

            this.setState({
                sum_down: profite_sum_down,
                car_excess_amount: car_excess_amount,
                vat_down: vat_down
            },()=>{
                this.handleOnChangeCarPriceOutof()
            })
        }
    }*/

    handleOnChangeInsurancePriceEdit(insurance_price_old) {
      if(this.state.insurance_buy ==='ซื้อเอง') {
        this.handleOnChangeCarPriceOutof();
      }
    }

    /*handleOnChangeInsurancePAPriceEdit(insurance_price_old) {
        if(this.state.insurance_pa_buy ==='PA ซื้อเอง') {
            this.handleOnChangeCarPriceOutof();
        }
    }*/

    loadBankData () {
        axios.get(APIURL + '/bank/')
        .then(res => this.setState({bank: res.data}))
        .catch(error => {
            this.setState({bank: []})
        })
    }

    loadSalesTypeData () {
        axios.get(APIURL + '/sales_type/')
            .then(res => {
                this.setState({sales_type: res.data})
            }).catch(error => {
            this.setState({sales_type: []})
        })
    }

    loadCarSellTypeData () {
        axios.get(APIURL + '/car_sell_type/')
            .then(res => {
                this.setState({car_sell_type: res.data})
            }).catch(error => {
            this.setState({car_sell_type: []})
        })
    }

    loadInsuranceCompanyData () {
        axios.get(APIURL + '/insurance_company/')
            .then(res => {
                this.setState({insurance_company: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({insurance_company: []})
        })
    }

    loadInsuranceTypeData () {
        axios.get(APIURL + '/insurance_type/')
            .then(res => {
                this.setState({insurance_type: res.data})
            }).catch(error => {
            this.setState({insurance_type: []})
        })
    }

    loadCountPeriodData () {
        axios.get(APIURL + '/count_period/')
            .then(res => {
                this.setState({count_period: res.data})
            }).catch(error => {
            this.setState({count_period: []})
        })
    }

    loadDurationPeriodData () {
        axios.get(APIURL + '/duration_period/')
            .then(res => {
                this.setState({duration_period: res.data})
            }).catch(error => {
            this.setState({duration_period: []})
        })
    }

    loadCarSellListPayData () {
        axios.get(APIURL + '/car_sell_list_pay/')
            .then(res => {
                this.setState({car_sell_list_pay: res.data})
            }).catch(error => {
            this.setState({car_sell_list_pay: []})
        })
    }

    loadCarStatusData () {
        axios.get(APIURL + '/car_status/sell/1')
            .then(res => {
                this.setState({car_status: res.data})
            }).catch(error => {
            this.setState({car_status: []})
        })
    }

    loadMoreDetailData () {
        axios.get(APIURL + '/more_detail/')
            .then(res => {
                this.setState({more_detail: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({more_detail: []})
        })
    }

    loadPromotionData () {
        axios.get(APIURL + '/promotion/')
            .then(res => {
                this.setState({promotion_list: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({promotion_list: []})
        })
    }

    loadEmployeeData() {
        axios.get(APIURL + '/employee/')
            .then(res => {
                if(res.status === 200) {
                    let data = res.data
                    if (data.length > 0) {
                        let dataFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1)
                        this.setState({ employees: dataFilter })
                    } else {
                        this.setState({employees: [] })
                    }
                }
            }).catch(error => {
            console.log('error:', error);
            this.setState({employees: [] })
        })
    }

    loadLicienseStatus() {
        axios.get(APIURL + '/liciense_status/')
            .then(res => {
                this.setState({liciense_status: res.data})
            }).catch(error => {
            this.setState({liciense_status: []})
        })
    }

    /*loadData () {
        axios.get(APIURL + '/car_sell/')
            .then(res => {
                this.setState({lists: res.data})
            }).catch(error => {
            this.setState({lists: []})
        })
    }*/

    componentWillMount() {
        this.setState({
            user: JSON.parse(this.props.user)
        });
    }

    componentDidMount() {
        // if(this.state.load_data)
        // this.loadCarData()
        // this.loadData()
        this.loadDataCarSellPay();
        this.loadBankData()
        this.loadCarSellTypeData()
        this.loadInsuranceCompanyData()
        this.loadInsuranceTypeData()
        this.loadCountPeriodData()
        this.loadDurationPeriodData()
        this.loadCarSellListPayData()
        this.loadCarStatusData()
        this.loadSalesTypeData()
        this.loadMoreDetailData()
        this.loadEmployeeData()
        this.loadPromotionData()
        this.loadLicienseStatus ()

        // let car_status_id = this.state.car_status_id
        // console.log('car_status_id=', car_status_id, typeof car_status_id)
    }

    loadDataCarSellPay () {

        let car_id = this.props.car_sell.car_id;
        let customer_id = this.props.car_sell.customer_id
        // let car_sell = this.props.car_sell

        axios.get(APIURL + '/car_sell_pay/buy/' + car_id + '/' + customer_id)
            .then(res => {
                // car_sell_pay.car_sell_pay_count
                let car_sell_pay_list = res.data;
                let sum_pay = 0;
                if (car_sell_pay_list.length > 0){
                    sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)
                }

                this.setState({
                    sum_pay: sum_pay
                }, () => {
                    this.handleOnChangeCarPriceOutof()
                    // update ยาก
                    // this.props.setCarSellRemain(remain, car_sell.car_sell_id)
                })
            }).catch(error => {
              console.log('error:', error)
            // this.setState({car_sell_pay_list: []})
        })
    }

  onInsuranceBuyChanged(e) {
    let insurance_buy = e.currentTarget.value
    this.setState({
      insurance_buy: insurance_buy
    },()=>{
         this.handleOnChangeCarPriceOutof()
    });
  }

    onInsurancePABuyChanged(e) {
        let insurance_pa_buy = e.currentTarget.value
        this.setState({
            insurance_pa_buy: insurance_pa_buy
        },()=>{
            this.handleOnChangeCarPriceOutof()
        });
    }

  handleOnChangeCarPriceOutof () {
    let sum_down = this.state.sum_down // ยอดดาวน์
    let insurance_price = (this.state.insurance_buy ==='ประกันแถม') ? 0 : this.state.insurance_price //ราคาประกัน
    let cost_finance = Number(this.state.cost_finance) //ค่าจัดไฟแนนซ์ //ค่าดำเนินการ/ค่าโอน
    let rate_finance =  Number(this.state.rate_finance) // ยอดจัดไฟแนนซ์
    let cost_transfer = 0 //this.state.cost_transfer //ค่าโอน
    // var car_pay_all = this.state.car_pay_all // เงินที่จ่ายไปแล้ว เงินจอง เงินมัดจำ
    let real_sell_price = Number(this.state.real_sell_price)
    let normal_sell_price =  Number(this.state.normal_sell_price)
    // let car_external_commission = 0 //Number(this.state.car_external_commission) //ค่านายหน้า ภายนอก
    let pay_advance_finance = this.state.pay_advance_finance // เก็บค่างวดรถล่วงหน้า
    let insurance_pa = (this.state.insurance_pa_buy ==='PA แถม') ? 0 : this.state.insurance_pa // PA
    // var car_excess_amount = Number(this.state.car_excess_amount) //ยอดจัดเกิน
    let customer_cash = 0 // คิดเงินคืนลูกค้า
    let gearbox_amount =  Number(this.state.gearbox_amount) // ยอดประกันเครื่องเกียร์
    let debt_insurance_amount =  Number(this.state.debt_insurance_amount) // ประกันภาระหนี้
    let cost_sell_act = Number(this.state.cost_sell_act) //ค่า พรบ.

    if (port === '9005' || port === 9005) {
      // pisan
      insurance_pa = 0
    }

    if (sum_down === '')
      sum_down = 0

    if (insurance_price === '')
      insurance_price = 0

      if (insurance_pa === '')
          insurance_pa = 0

    if (cost_finance === '')
      cost_finance = 0

    if (cost_transfer === '')
      cost_transfer = 0

      if (cost_sell_act === '')
          cost_sell_act = 0

    let profite_total_outof_car = 0 // เงินออกรถ

    if (sum_down !== '' && sum_down > 0) {
      profite_total_outof_car = Number(sum_down) + Number(insurance_price) + Number(cost_finance) + Number(cost_transfer) +
                                Number(pay_advance_finance) + Number(insurance_pa) + gearbox_amount + debt_insurance_amount + cost_sell_act
    }else if(sum_down===0){
      profite_total_outof_car = Number(insurance_price) + Number(cost_finance) + Number(cost_transfer) + Number(pay_advance_finance) +
                                Number(insurance_pa) + gearbox_amount + debt_insurance_amount + cost_sell_act
    }

    if (this.state.car_sell_type_id === '1') { //ประเภทการขาย 1 = เงินสด
      profite_total_outof_car = Number(real_sell_price) + Number(insurance_price) + Number(cost_finance) + Number(cost_transfer) +
                                Number(pay_advance_finance) + Number(insurance_pa) + gearbox_amount + debt_insurance_amount + cost_sell_act

      // console.log('outof_car=',profite_total_outof_car)
    }

    let discount = 0
    //var profite_sum_down = 0

    if (normal_sell_price !== '' && normal_sell_price > 0) {
      discount = Number(normal_sell_price) - Number(real_sell_price)
    }

    let real_discount = discount >= 0 ? discount: 0 ;

    let car_over_sales = 0;
    if(real_sell_price > normal_sell_price )
      car_over_sales = real_sell_price - normal_sell_price

    let car_excess_amount = 0; // ยอดจัดเกิน
    if(rate_finance > real_sell_price) {
      // profite_sum_down = 0
      car_excess_amount = rate_finance - real_sell_price;
    }
      // else {
      // ยอดจัดไม่เกินยอดขาย
      // rate_finance + sum_down >= real_sell_price
      // }


      let is_key_vat_down = this.state.is_key_vat_down
      let vat_down = 0
      if (is_key_vat_down) {
          vat_down = Number(this.state.vat_down)
      } else {
          vat_down = this.state.is_vat === 0 ? 0 : Number(this.state.vat_down)
      }

      let total_outof_discount = Number(this.state.total_outof_discount)
      profite_total_outof_car = profite_total_outof_car + vat_down
      // console.log('profite_total_outof_car =', profite_total_outof_car)
      // console.log('vat_down =', vat_down)
      profite_total_outof_car = profite_total_outof_car - total_outof_discount

      if (this.state.car_sell_type_id === '2') {
          // ขายไฟแนนซ์
          // ยอดจัดเกิน
          if (car_excess_amount >= profite_total_outof_car) {
              customer_cash = car_excess_amount - profite_total_outof_car
              profite_total_outof_car = 0
          } else {
              // ราคา ออกรถมากกว่า
              customer_cash = 0
              profite_total_outof_car = Number(profite_total_outof_car) - Number(car_excess_amount)
          }
      }

    // let payMore = real_sell_price - ( rate_finance + profite_total_outof_car)
    // let balance_debt = Number(profite_total_outof_car)

    // if(payMore > 0){
    //   balance_debt += payMore
    // }

    // let is_key_vat_down = this.state.is_key_vat_down
    // let vat_down = 0
    // if (is_key_vat_down) {
    //   vat_down = Number(this.state.vat_down)
    // } else {
    //   vat_down = this.state.is_vat === 0 ? 0 : Number(this.state.vat_down)
    // }
    //
    //   let total_outof_discount = Number(this.state.total_outof_discount)
    //
    // profite_total_outof_car = profite_total_outof_car + vat_down
    //   // console.log('profite_total_outof_car =', profite_total_outof_car)
    //   // console.log('vat_down =', vat_down)
    //   profite_total_outof_car = profite_total_outof_car - total_outof_discount



    let sum_pay = Number(this.state.sum_pay)
    let balance_debt = profite_total_outof_car - sum_pay
    let balance_debt_fix = balance_debt <= 0 ? 0 : balance_debt.toFixed(2)
    // console.log('balance_debt', balance_debt)
    // console.log('balance_debt_fix', balance_debt_fix)
    // console.log('sum_pay', sum_pay)
    this.setState({
      total_outof_car: profite_total_outof_car, // รวมออกรถ/บาท
      customer_cash: customer_cash,
      balance_debt: balance_debt_fix,
      car_sell_discount: real_discount,
      car_excess_amount: car_excess_amount,
      car_over_sales: car_over_sales
    })
  }

  handleCarIssueDate (date) {
    this.setState({
      car_issue_date: date
    })
  }

  handleFistInstallmentDate(date) {
    this.setState({
      first_installment: date
    })
  }

  handleSumDown() {

  }

    render(){

        let form_controls = this.props.form_controls

        let bank_finance_list = this.state.bank.map((bank,index)=>{
            return(
                <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
        })

        let car_sell_type_list = this.state.car_sell_type.map((car_sell_type,index)=>{
            return(
                <option key={index} value={car_sell_type.car_sell_type_id}>{car_sell_type.car_sell_type_name}</option>
            )
        })

        let insurance_company_list = this.state.insurance_company.map((insurance_company,index)=>{
            return(
                <option key={index} value={insurance_company.insurance_company_id}>{insurance_company.insurance_company_name}</option>
            )
        })

        let insurance_type_list = this.state.insurance_type.map((insurance_type,index)=>{
            return(
                <option key={index} value={insurance_type.insurance_type_id}>{insurance_type.insurance_type_name}</option>
            )
        })

        /* var count_period_list = this.state.count_period.map((count_period,index)=>{
            return(
                <option key={index} value={count_period.count_period_id}>{count_period.count_period_name}</option>
            )
        }) */

        let duration_period_list = this.state.duration_period.map((duration_period,index)=>{
            return(
                <option key={index} value={duration_period.duration_period_id}>{duration_period.duration_period_name}</option>
            )
        })

        let car_sell_status_list = this.state.car_status.map((car_status,index)=>{
            return(
                <option key={index} value={car_status.car_status_id}>{car_status.car_status_name}</option>
            )
        })

        let promotion_list = this.state.promotion_list.map((promotion, index) => {
          return (
           <option key={index} value={promotion.promotion_name}> {promotion.promotion_name} </option>
          )
        })

        let more_detail_list = this.state.more_detail.map((more_detail, index) => {
            return (
                <option key={index} value={more_detail.more_detail_id}>{more_detail.more_detail_name}</option>
            )
        })

        let employee_list = this.state.employees.map((employee, index) => {
            return (
                <option key={index} value={employee.employee_id}> {employee.employee_name}&nbsp;&nbsp;{employee.employee_lastname} </option>
            )
        })

        let liciense_status_list = this.state.liciense_status.map((liciense_status, index) => {
            return (
                <option key={index} value={liciense_status.liciense_status_id}>{liciense_status.liciense_status_name}</option>
            )
        })

        let normal_sell_price = null;

        if(this.state.user.type === 'admin'){
          normal_sell_price = <NumberFormat
            className="form-control"
            thousandSeparator={true}
            prefix={'฿'}
            // placeholder="กรอกค่าโอน"
            min={0}
            max={1000000000}
            step={1000}
            size={10}
            allowNegative={false}
            value={this.state.normal_sell_price}
            onValueChange = {(values) => {
              const {value} = values;
                // TODO: console.log(this.state.user.type)
                this.setState({normal_sell_price: value}, () => {
                  this.handleOnChangeCarSellEditPrice();
                })
            }}
          />
        }else {
          normal_sell_price = <NumberFormat
            className="form-control"
            thousandSeparator={true}
            prefix={'฿'}
            //placeholder="กรอกค่าโอน"
            displayType={'text'}
            allowNegative={false}
            value={this.state.normal_sell_price}
          />
        }


      let gearbox_warranty_list = this.state.gearbox_warranty_list.map((gearbox_warranty, index) =>{
        return (
          <option key={index} value={gearbox_warranty.gearbox_warranty_name}> {gearbox_warranty.gearbox_warranty_name} </option>
        )
      });

        return(
            <form action="" method="post" onSubmit={this.handleOnSubmitCarSellEdit}>
                <div className="row" >
                    <div className="col-sm-12">
                        <div className="card card-accent-warning">
                            <div className="card-header card-sparepart">
                                <strong className="text-title">แก้ไขข้อมูลการขายรถ</strong>
                            </div>

                            <div className="card-block">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className="row">
                                            <div className="form-group form-inline col-sm-4">
                                                <label htmlFor="car_brand_name">ยี่ห้อ </label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_brand_name}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_model_name">รุ่นรถ</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_model_name}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_sub_model_name">รุ่นย่อย </label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_sub_model_name}</strong>
                                               </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_year_name">ปีรถ</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_year_name}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_gear_name">ระบบเกียร์</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_gear_name}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_color_name">สีรถ</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_color_name}</strong>
                                               </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_miles">เลขไมล์</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_miles}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_license_plate_new">ป้ายทะเบียนรถ</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.car_license_plate_new}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-4">
                                                <label htmlFor="province_name_new">จังหวัด</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.province_name_new}</strong>
                                               </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="customer_name">ชื่อลูกค้า</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.customer_name}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-6">
                                                <label htmlFor="customer_lastname">นามสกุลลูกค้า</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.customer_lastname}</strong>
                                               </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="customer_card_id">เลขประจำตัวประชาชน</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.customer_card_id}</strong>
                                               </span>
                                            </div>

                                            <div className="form-group col-sm-6">
                                                <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.customer_mobile}</strong>
                                               </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
                                                <span className="text-primary pl-5">
                                                    <strong>{this.state.customer_address}</strong>
                                               </span>
                                            </div>
                                        </div>

                                        {/*แก้ไขผู้ขาย 9048 sc ขอมา*/}
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="car_sell_type">ขายโดย</label>
                                                <SelectSearch
                                                    ref="sale_by_id"
                                                    options={this.state.employees}
                                                    value={this.state.sale_by_id}
                                                    search={true}
                                                    name="sale_by_id"
                                                    placeholder="เลือกผู้ขาย"
                                                    onChange={(val) => {

                                                        //let employees = this.state.employees
                                                        //let income_expense = employees.filter(item => item.employee_id === val)
                                                        //let income_expense_item_name = income_expense[0].income_expense_item_name

                                                        this.setState({
                                                            sale_by_id: val,
                                                        })
                                                    }}
                                                />
                                                {/*<select
                                                    className="form-control"
                                                    id="sale_by_id"
                                                    name="sale_by_id"
                                                    ref="sale_by_id"
                                                    value={this.state.sale_by_id}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            sale_by_id: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''> เลือกพนักงานผู้ขาย </option>
                                                    {employee_list}
                                                </select>*/}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="car_sell_type_id">ประเภทการขาย</label>
                                                <select
                                                    className="form-control"
                                                    id="car_sell_type_id"
                                                    ref="car_sell_type_id"
                                                    name="car_sell_type_id"
                                                    onChange={this.onInputChangeCarSellEdit}
                                                    value={this.state.car_sell_type_id}
                                                >
                                                    <option value=""> เลือกประเภทการขาย </option>
                                                    {car_sell_type_list}
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="bank_finance_id">ไฟแนนซ์</label>
                                                <select
                                                    className="form-control"
                                                    id="bank_finance_id"
                                                    name="bank_finance_id"
                                                    ref="bank_finance_id"
                                                    disabled={this.state.car_sell_type_id === '1'}
                                                    onChange={this.onInputChangeCarSellEdit}
                                                    value={this.state.bank_finance_id}
                                                >
                                                    <option> เลือกธนาคาร </option>
                                                    {bank_finance_list}
                                                </select>
                                            </div>

                                          <div className="form-group col-sm-4">
                                            <label htmlFor="sell_finance_contract">ชื่อพนักงานไฟแนนซ์</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="sell_finance_contract"
                                                   name="sell_finance_contract"
                                                   placeholder=""
                                                   ref="sell_finance_contract"
                                                   maxLength={100}
                                                   value={this.state.sell_finance_contract}
                                                   disabled={this.state.car_sell_type_id === '1'}
                                                   onChange={(e)=>{
                                                     this.setState({
                                                       sell_finance_contract: e.target.value
                                                     })
                                                   }}
                                            />
                                          </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="normal_sell_price">ราคาปกติ</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="normal_sell_price"
                                                       name="normal_sell_price"
                                                       placeholder=""
                                                       ref="normal_sell_price"
                                                       value={this.state.normal_sell_price}
                                                       onChange={()=>{}}
                                                />
                                                {normal_sell_price}
                                            </div>

                                            <div className="form-group col-sm-3">
                                                <label htmlFor="real_sell_price">ราคาขายจริง</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="real_sell_price"
                                                       name="real_sell_price"
                                                       placeholder=""
                                                       ref="real_sell_price"
                                                       value={this.state.real_sell_price}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.real_sell_price}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({real_sell_price: value},()=>{
                                                            this.handleOnChangeCarSellEditPrice();
                                                        })

                                                    }}
                                                />
                                            </div>

                                          <div className="form-group col-sm-3 has-warning">
                                            <label htmlFor="car_over_sales">ยอดขายเกิน</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="car_over_sales"
                                                   name="car_over_sales"
                                                   placeholder=""
                                                   ref="car_over_sales"
                                                   value={this.state.car_over_sales}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control text-primary"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              //placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={true}
                                              value={this.state.car_over_sales}
                                              displayType="text"
                                            />
                                          </div>

                                            <div className="form-group col-sm-3">
                                                <label htmlFor="car_sell_discount">ส่วนลด</label>
                                                <input readOnly
                                                       type="text"
                                                       className="form-control hidden"
                                                       id="car_sell_discount"
                                                       name="car_sell_discount"
                                                       placeholder=""
                                                       ref="car_sell_discount"
                                                       value={this.state.car_sell_discount}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={true}
                                                    value={this.state.car_sell_discount}
                                                    displayType="text"
                                                />
                                            </div>

                                            <div className="form-group col-sm-2">
                                                <label htmlFor="rate_finance">ยอดจัดไฟแนนซ์</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="rate_finance"
                                                       name="rate_finance"
                                                       placeholder=""
                                                       ref="rate_finance"
                                                       value={this.state.rate_finance}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    disabled={this.state.car_sell_type_id === '1'}
                                                    value={this.state.rate_finance}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({
                                                            rate_finance: value,
                                                            is_key_vat_down: false
                                                        },()=>{
                                                           this.handleOnChangeCarSellEditPrice();
                                                        })
                                                    }}
                                                />
                                            </div>

                                            {/*<div className="form-group col-sm-3 hidden">
                                                <label htmlFor="insurance_pa">ประกัน PA</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="insurance_pa"
                                                       name="insurance_pa"
                                                       placeholder=""
                                                       ref="insurance_pa"
                                                       value={this.state.insurance_pa}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.insurance_pa}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({insurance_pa: value},()=>{
                                                            this.handleOnChangeCarSellEditPrice();
                                                        })

                                                    }}
                                                />
                                            </div>*/}

                                            <div className="form-group col-sm-3 hidden">
                                                <label htmlFor="bank">ภาษียอดจัดไฟแนนซ์</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="tax_rate_finance"
                                                       name="tax_rate_finance"
                                                       placeholder=""
                                                       ref="tax_rate_finance"
                                                       value={this.state.tax_rate_finance}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    // placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.tax_rate_finance}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({tax_rate_finance: value},()=>{
                                                            this.handleOnChangeCarSellEditPrice();
                                                        })

                                                    }}
                                                />
                                            </div>

                                            <div className="form-group col-sm-3 hidden">
                                                <label htmlFor="total_rate_finance">รวมมูลค่ายอดจัดไฟแนนซ์</label>
                                                <input readOnly
                                                       type="text"
                                                       className="form-control hidden"
                                                       id="total_rate_finance"
                                                       name="total_rate_finance"
                                                       placeholder=""
                                                       ref="total_rate_finance"
                                                       value={this.state.total_rate_finance}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.total_rate_finance}
                                                    displayType="text"
                                                />
                                            </div>

                                          <div
                                              className={checkFormControls('sell_discount', form_controls) ? "form-group col-sm-2" : "hidden"}
                                          >
                                            <label htmlFor="sell_discount">ส่วนลดเงินดาวน์
                                            </label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="sell_discount"
                                                   name="sell_discount"
                                                   placeholder=""
                                                   ref="sell_discount"
                                                   value={this.state.sell_discount}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              //placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              displayType={Number(this.state.car_excess_amount) > 0 ? 'text': 'input' }
                                              value={this.state.sell_discount}
                                              disabled={this.state.car_sell_type_id === '1'}
                                              onValueChange = {(values) => {
                                                const {value} = values;

                                                  const real_sell_price = Number(this.state.real_sell_price);
                                                  const rate_finance = Number(this.state.rate_finance);
                                                  let sell_discount = value === '' ? 0 : Number(value);
                                                  let sum_down = Number(this.state.sum_down)

                                                  if(sell_discount === 0 && sum_down === 0 && rate_finance >= real_sell_price ){
                                                      this.setState({
                                                          sell_discount: 0,
                                                          sum_down: 0,
                                                          vat_down: 0,
                                                      })
                                                      return
                                                  }

                                                  const real_with_rate = sell_discount + rate_finance

                                                    if(real_with_rate >= real_sell_price){
                                                        this.setState({
                                                            sell_discount: sell_discount,
                                                            sum_down: 0,
                                                            vat_down: 0,
                                                        })
                                                        return;
                                                    }

                                                 if(sell_discount === 0) {
                                                     const real_sell_price = this.state.real_sell_price;
                                                     const rate_finance = this.state.rate_finance;
                                                     const sum_down = Number(real_sell_price) - Number(rate_finance) - Number(sell_discount)
                                                     const vat = Number(this.state.vat);
                                                     let vat_down_str = String(sum_down * (vat/100.0));
                                                     let vat_down_fix = parseFloat(vat_down_str).toFixed(2);
                                                     this.setState({
                                                         sell_discount: 0,
                                                         sum_down: sum_down,
                                                         vat_down: PORT === 9048 ? 0 : vat_down_fix,
                                                     })
                                                     return;
                                                 }

                                                  let sum_down_new = Number(real_sell_price) - rate_finance - sell_discount
                                                  const vat = Number(this.state.vat);
                                                  let vat_down_str = String(sum_down_new * (vat/100.0));
                                                  let vat_down_fix = parseFloat(vat_down_str).toFixed(2);

                                                  this.setState({
                                                      sell_discount: sell_discount,
                                                      sum_down: sum_down_new,
                                                      vat_down: PORT === 9048 ? 0 : vat_down_fix,
                                                  })
                                              }}
                                            />
                                          </div>

                                            <div className="form-group col-sm-2">
                                                <label htmlFor="sum_down">ยอดดาวน์</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="sum_down"
                                                       name="sum_down"
                                                       placeholder=""
                                                       ref="sum_down"
                                                       value={this.state.sum_down}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.sum_down}
                                                    disabled={this.state.car_sell_type_id === '1'}
                                                    onValueChange = {(values) => {
                                                      //   const {value} = values;
                                                      //   // console.log('value=', value)
                                                        this.handleOnChangeCarPriceOutof()
                                                      //
                                                      // let sum_down = value === '' ? 0 : value;
                                                      // const real_sell_price = this.state.real_sell_price;
                                                      // const rate_finance = this.state.rate_finance;
                                                      //
                                                      // // const sell_discount = this.state.sell_discount;
                                                      // const sell_discount = Number(real_sell_price) - Number(rate_finance) - Number(sum_down)
                                                      //
                                                      // const vat = Number(this.state.vat);
                                                      // let vat_down_str = String(sum_down * (vat/100.0));
                                                      // let vat_down_fix = parseFloat(vat_down_str).toFixed(2);
                                                      //
                                                      //   let vat_down = PORT === 9048 ? 0 : vat_down_fix
                                                      //
                                                      //   this.setState({
                                                      //     sum_down: sum_down,
                                                      //     vat_down: vat_down,
                                                      //     sell_discount: sell_discount
                                                      //   },()=>{
                                                      //      this.handleOnChangeCarSellEditPrice();
                                                      //   })

                                                    }}

                                                    /*onBlur={(e) => {
                                                      let xx = e.target.value
                                                      console.log('xx=', xx)
                                                    }}*/

                                                />
                                            </div>


                                          <div className={this.state.is_vat === 1 && PORT !== 9048 ? "form-group col-sm-2" : "hidden" }>
                                            <label htmlFor="vat_down">VAT ดาวน์</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="vat_down"
                                                   name="vat_down"
                                                   placeholder=""
                                                   ref="vat_down"
                                                   value={this.state.vat_down}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              //placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.vat_down}
                                              disabled={this.state.car_sell_type_id === '1'}
                                              onValueChange = {(values) => {
                                                const {value} = values


                                                this.setState({
                                                  vat_down: value,
                                                  is_key_vat_down: true
                                                }, () => {
                                                  this.handleOnChangeCarPriceOutof()
                                                })
                                              }}
                                            />
                                          </div>

                                          <div className="form-group col-sm-2 has-warning">
                                            <label htmlFor="car_excess_amount">ยอดจัดเกิน</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="car_excess_amount"
                                                   name="car_excess_amount"
                                                   placeholder=""
                                                   ref="car_excess_amount"
                                                   value={this.state.car_excess_amount}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control text-primary"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              //placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.car_excess_amount}
                                              displayType="text"
                                              //disabled={this.state.car_sell_type_id === '1'}
                                            />
                                          </div>

                                            <div className="form-group col-sm-2">
                                                <label htmlFor="insurance_company_id">บ.ประกัน</label>
                                                <select
                                                    className="form-control"
                                                    id="insurance_company_id"
                                                    name="insurance_company_id"
                                                    ref="insurance_company_id"
                                                    onChange={this.onInputChangeCarSellEdit}
                                                    value={this.state.insurance_company_id}
                                                >
                                                  <option value=""> เลือก บ.ประกัน</option>
                                                    {insurance_company_list}
                                                </select>
                                            </div>

                                            <div className="form-group col-sm-2">
                                                <label htmlFor="insurance_type">ประเภทประกัน</label>
                                                <select
                                                    className="form-control"
                                                    id="insurance_type_id"
                                                    name="insurance_type_id"
                                                    ref="insurance_type_id"
                                                    onChange={this.onInputChangeCarSellEdit}
                                                    value={this.state.insurance_type_id || "0"}
                                                >
                                                  <option value="0"> เลือกประเภทประกัน </option>
                                                    {insurance_type_list}
                                                </select>
                                            </div>

                                            <div className="form-group col-sm-2">
                                                <label htmlFor="insurance_price">ราคาประกัน</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="insurance_price"
                                                       name="insurance_price"
                                                       placeholder=""
                                                       ref="insurance_price"
                                                       value={this.state.insurance_price}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.insurance_price}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        let insurance_price_old = this.state.insurance_price
                                                        this.setState({insurance_price: value},()=>{
                                                            this.handleOnChangeInsurancePriceEdit(insurance_price_old);
                                                        })
                                                    }}
                                                />
                                            </div>

                                            {/* new item for nkmahachai start */}
                                            <div className="form-group col-sm-2">
                                                <label htmlFor="insurance_buy">การซื้อประกัน</label>
                                                <br />
                                                {/*<div className="form-control">
                                              {this.state.insurance_buy}
                                            </div>*/}
                                                <input type="radio"
                                                       name="insurance_buy"
                                                       value="ประกันแถม"
                                                       className="btn btn-primary"
                                                       defaultChecked={this.state.insurance_buy === 'ประกันแถม'}
                                                       onChange={this.onInsuranceBuyChanged}
                                                /> &nbsp;<span style={{marginTop: -4}}>ประกันแถม</span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio"
                                                       name="insurance_buy"
                                                       value="ซื้อเอง"
                                                       className="btn btn-primary"
                                                       defaultChecked={this.state.insurance_buy === 'ซื้อเอง'}
                                                       onChange={this.onInsuranceBuyChanged}
                                                /> &nbsp;<span style={{marginTop: -4}}>ซื้อเอง</span>
                                            </div>


                                          <div className="form-group col-sm-2">
                                            <label htmlFor="insurance_pa">เก็บค่าประกันชีวิต PA</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="insurance_pa"
                                                   name="insurance_pa"
                                                   placeholder=""
                                                   value={this.state.insurance_pa}
                                                   onChange={() => {}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.insurance_pa}
                                              disabled={this.state.car_sell_type_id === '1'}
                                              onValueChange={(values) => {
                                                  let insurance_pa = 0;
                                                  if (values.value === '') {
                                                      insurance_pa = 0;
                                                  } else {
                                                      insurance_pa = values.floatValue
                                                  }
                                                this.setState({insurance_pa: insurance_pa}, () => {
                                                  this.handleOnChangeCarPriceOutof();
                                                })
                                              }}
                                            />
                                          </div>

                                            <div className="form-group col-sm-2">
                                                <label htmlFor="insurance_pa_buy">การซื้อประกันชีวิต PA</label>
                                                <br />
                                                <input type="radio"
                                                       name="insurance_pa_buy"
                                                       value="PA แถม"
                                                       className="btn btn-primary"
                                                       defaultChecked={this.state.insurance_pa_buy === 'PA แถม'}
                                                       onChange={this.onInsurancePABuyChanged}
                                                       disabled={this.state.car_sell_type_id === '1'}
                                                /> &nbsp;<span style={{marginTop: -4}}>PA แถม</span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio"
                                                       name="insurance_pa_buy"
                                                       value="PA ซื้อเอง"
                                                       className="btn btn-primary"
                                                       defaultChecked={this.state.insurance_pa_buy === 'PA ซื้อเอง'}
                                                       onChange={this.onInsurancePABuyChanged}
                                                       disabled={this.state.car_sell_type_id === '1'}
                                                /> &nbsp;<span style={{marginTop: -4}}>PA ซื้อเอง</span>
                                            </div>

                                          <div className="form-group col-sm-2">
                                            <label htmlFor="insurance_pa">เก็บค่างวดรถล่วงหน้า</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="pay_advance_finance"
                                                   name="pay_advance_finance"
                                                   placeholder=""
                                                   value={this.state.pay_advance_finance}
                                                   onChange={() => {}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.pay_advance_finance}
                                              disabled={this.state.car_sell_type_id === '1'}
                                              onValueChange={(values) => {

                                                  let pay_advance_finance = 0;
                                                  if (values.value === '') {
                                                      pay_advance_finance = 0;
                                                  } else {
                                                      pay_advance_finance = values.floatValue
                                                  }

                                                this.setState({pay_advance_finance: pay_advance_finance}, () => {
                                                  this.handleOnChangeCarPriceOutof();
                                                })
                                              }}
                                            />
                                          </div>


                                            {/*<div className="form-group col-sm-3 hidden">
                                                <label htmlFor="cost_finance">ค่าจัดไฟแนนซ์+ค่าโอน</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="cost_finance"
                                                       name="cost_finance"
                                                       placeholder=""
                                                       value={this.state.cost_finance}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    // placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.cost_finance}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({cost_finance: value},()=>{
                                                            //console.log(this.state.cost_finance)
                                                            this.handleOnChangeCarSellEditPrice();
                                                        })
                                                    }}
                                                />
                                            </div>*/}

                                          <div className="form-group col-sm-2">
                                            <label htmlFor="duration_period_id">ระยะเวลาผ่อนชำระ</label>
                                            <select
                                              className="form-control"
                                              id="duration_period_id"
                                              name="duration_period_id"
                                              ref="duration_period_id"
                                              disabled={this.state.car_sell_type_id === '1'}
                                              onChange={this.onInputChangeCarSellEdit}
                                              value={this.state.duration_period_id}
                                            >
                                              <option> เลือกระยะเวลาผ่อนชำระ </option>
                                              {duration_period_list}
                                            </select>
                                          </div>

                                          <div className="form-group col-sm-2">
                                            <label htmlFor="promotion_name">ชื่อโปรโมชั่น</label>
                                            {/* <div className="form-control">
                                              {this.state.promotion_name}
                                            </div> */}
                                            <select
                                              className="form-control"
                                              id="promotion_name"
                                              name="promotion_name"
                                              ref="promotion_name"
                                              value={this.state.promotion_name}
                                              onChange={(e)=>{
                                                  this.setState({promotion_name: e.target.value});
                                              }}
                                            >
                                              <option value=""> ระบุโปรโมชั่น </option>
                                              {promotion_list}
                                            </select>
                                          </div>

                                          <div className="form-group col-sm-2">
                                            <label htmlFor="gearbox_warranty">ประกันเครื่องเกียร์</label>
                                            <select
                                              className="form-control"
                                              id="gearbox_warranty"
                                              name="gearbox_warranty"
                                              value={this.state.gearbox_warranty}
                                              onChange={(e) => {
                                                let val = e.target.value
                                                this.setState({
                                                  gearbox_warranty: val
                                                }, () => {
                                                  if (val === 'ไม่รับประกันเครื่องเกียร์') {
                                                    this.setState({
                                                      gearbox_bank_name: '',
                                                      gearbox_amount: 0
                                                    })
                                                  }
                                                })
                                              }}
                                            >
                                              <option value=""> ระบุการประกันเครื่องเกียร์ </option>
                                              {gearbox_warranty_list}
                                            </select>

                                          </div>

                                          <div className="form-group col-sm-2">
                                            <label htmlFor="gearbox_warranty">ธ.ที่รับประกันเครื่องเกียร์</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="gearbox_bank_name"
                                                   name="gearbox_bank_name"
                                                   maxLength={100}
                                                   placeholder=""
                                                   value={this.state.gearbox_bank_name}
                                                   disabled={this.state.gearbox_warranty !== 'รับประกันเครื่องเกียร์'}
                                                   onChange={(event)=>{
                                                     this.setState({gearbox_bank_name: event.target.value});
                                                   }}
                                            />
                                          </div>

                                          <div className="form-group col-sm-2 ">
                                            <label htmlFor="gearbox_amount">ยอดประกันเครื่องเกียร์</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   name="gearbox_amount"
                                                   placeholder=""
                                                   value={this.state.gearbox_amount}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              // placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.gearbox_amount}
                                              onValueChange = {(values) => {
                                                const {value} = values;
                                                this.setState({gearbox_amount: value},()=>{
                                                  this.handleOnChangeCarPriceOutof();
                                                })
                                              }}
                                            />
                                          </div>

                                          <div className="form-group col-sm-2 ">
                                            <label htmlFor="debt_insurance_amount">ยอดประกันภาระหนี้</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   name="debt_insurance_amount"
                                                   placeholder=""
                                                   value={this.state.debt_insurance_amount}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              // placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.debt_insurance_amount}
                                              onValueChange = {(values) => {
                                                const {value} = values;
                                                this.setState({debt_insurance_amount: value},()=>{
                                                  this.handleOnChangeCarPriceOutof();
                                                })
                                              }}
                                            />
                                          </div>

                                          {/* new item for nkmahachai end */}

                                            {/*<div className="form-group col-sm-3 hidden">
                                                <label htmlFor="cost_transfer">ค่าโอน</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="cost_transfer"
                                                       name="cost_transfer"
                                                       placeholder=""
                                                       ref="cost_transfer"
                                                       value={this.state.cost_transfer}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.cost_transfer}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({cost_transfer: value},()=>{
                                                            this.handleOnChangeCarSellEditPrice();
                                                        })

                                                    }}
                                                />
                                            </div>*/}

                                            {/*<div className="form-group col-sm-3 hidden">
                                                <label htmlFor="cost_period_month">ค่างวดล่วงหน้า/เดือน</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="cost_period_month"
                                                       name="cost_period_month"
                                                       placeholder=""
                                                       ref="cost_period_month"
                                                       value={this.state.cost_period_month}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.cost_period_month}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({cost_period_month: value},()=>{
                                                            this.handleOnChangeCarSellEditPrice();
                                                        })

                                                    }}
                                                />
                                            </div>*/}

                                            {/*<div className="form-group col-sm-3 hidden">
                                                <label htmlFor="count_period">จำนวนงวด</label>
                                                <select
                                                    className="form-control"
                                                    id="count_period_id"
                                                    name="count_period_id"
                                                    ref="count_period_id"
                                                    onChange={this.onInputChangeCarSellEdit.bind(this)}
                                                    value={this.state.count_period_id}
                                                >
                                                    <option> เลือกจำนวนงวด </option>
                                                    {count_period_list}
                                                </select>
                                            </div>*/}

                                            {/*<div className="form-group col-sm-4 hidden">
                                                <label htmlFor="total_cost_period_call">รวมค่างวดที่เรียกเก็บ</label>
                                                <input readOnly
                                                       type="text"
                                                       className="form-control hidden"
                                                       id="total_cost_period_call"
                                                       name="total_cost_period_call"
                                                       placeholder=""
                                                       ref="total_cost_period_call"
                                                       value={this.state.total_cost_period_call}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.total_cost_period_call}
                                                    displayType="text"
                                                />
                                            </div>*/}

                                          {/*<div className="form-group col-sm-3">
                                            <label htmlFor="cost_finance">ค่านายหน้า</label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="car_external_commission"
                                                   name="car_external_commission"
                                                   placeholder=""
                                                   ref="car_external_commission"
                                                   value={this.state.car_external_commission}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              //placeholder="กรอกค่าโอน"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.car_external_commission}
                                              onValueChange={(values) => {
                                                const {value} = values
                                                this.setState({car_external_commission: value}, () => {
                                                  //this.handleOnChangeCarPriceOutof()
                                                })
                                              }}
                                            />
                                          </div>*/}
                                            <div className="form-group col-sm-2 ">
                                                <label htmlFor="cost_finance">ค่าดำเนินการ/ค่าโอน</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       name="cost_finance"
                                                       placeholder=""
                                                       value={this.state.cost_finance}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.cost_finance}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({cost_finance: value},()=>{
                                                            this.handleOnChangeCarPriceOutof();
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group col-sm-2 ">
                                                <label htmlFor="cost_sell_act">ค่า พรบ.</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       name="cost_sell_act"
                                                       placeholder=""
                                                       value={this.state.cost_sell_act}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.cost_sell_act}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({cost_sell_act: value},()=>{
                                                            this.handleOnChangeCarPriceOutof();
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group col-sm-2 ">
                                                <label htmlFor="total_outof_discount">ส่วนลดเงินออกรถ</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       name="total_outof_discount"
                                                       placeholder=""
                                                       value={this.state.total_outof_discount}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.total_outof_discount}
                                                    onValueChange = {(values) => {
                                                        const {value} = values;
                                                        this.setState({total_outof_discount: value},()=>{
                                                            this.handleOnChangeCarPriceOutof();
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group has-warning col-sm-3">
                                                <label htmlFor="total_outof_car">รวมออกรถ/บาท</label>
                                                <input readOnly
                                                       type="text"
                                                       className="form-control hidden"
                                                       id="total_outof_car"
                                                       name="total_outof_car"
                                                       placeholder=""
                                                       ref="total_outof_car"
                                                       value={this.state.total_outof_car}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.total_outof_car}
                                                    displayType="text"
                                                />
                                            </div>

                                            <div className="form-group has-danger col-sm-3 ">
                                                <label htmlFor="balance_debt">ยอดคงเหลือค้างชำระ/บาท</label>
                                                <input readOnly
                                                       type="text"
                                                       className="form-control hidden"
                                                       id="balance_debt"
                                                       name="balance_debt"
                                                       ref="balance_debt"
                                                       placeholder=""
                                                       value={this.state.balance_debt}
                                                       onChange={()=>{}}
                                                />
                                                <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    //placeholder="กรอกค่าโอน"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={true}
                                                    displayType="text"
                                                    value={this.state.balance_debt}
                                                    onChange={() => {}}
                                                />
                                                {/*<input readOnly
                                                       type="text"
                                                       className="form-control hidden"
                                                       id="balance_debt"
                                                       name="balance_debt"
                                                       ref="balance_debt"
                                                       placeholder=""
                                                       value={this.state.balance_debt}
                                                       onChange={()=>{}}
                                                />
                                                */}

                                             {
                                             //   this.state.car_sell_id  !== 0 && this.state.total_outof_car > 0 ? (
                                             //   <>
                                             //     <br/>
                                             //     <OutstandingBalanceComponent
                                             //        car_id={this.state.car_id}
                                             //        customer_id={this.state.customer_id}
                                             //        balance_debt={this.state.balance_debt}
                                             //        bank_finance_id={this.state.bank_finance_id}
                                             //        car_sell={this.props.car_sell}
                                             //        isShowText={false}
                                             //        balance_debt_input={true}
                                             //        total_outof_car = {this.state.total_outof_car}
                                             //     />
                                             //   </>
                                             // ): <input readOnly
                                             //           type="text"
                                             //           className="form-control"
                                             //           id="balance_debt"
                                             //           name="balance_debt"
                                             //           ref="balance_debt"
                                             //           placeholder=""
                                             //           value={this.state.balance_debt}
                                             //           onChange={()=>{}}
                                             //   />
                                             }



                                            </div>

                                          <div className="form-group has-success col-sm-3">
                                            <label htmlFor="customer_cash">เงินคืนลูกค้า </label>
                                            <input type="text"
                                                   className="form-control hidden"
                                                   id="customer_cash"
                                                   name="customer_cash"
                                                   placeholder=""
                                                   ref="customer_cash"
                                                   value={this.state.customer_cash}
                                                   onChange={()=>{}}
                                            />
                                            <NumberFormat
                                              className="form-control text-primary"
                                              thousandSeparator={true}
                                              prefix={'฿'}
                                              //placeholder="เงินคืนลูกค้า"
                                              min={0}
                                              max={1000000000}
                                              step={1000}
                                              size={10}
                                              allowNegative={false}
                                              value={this.state.customer_cash}
                                              displayType="text"
                                            />
                                          </div>

                                            <div
                                                className={checkFormControls('refund_type', form_controls) ? "form-group col-sm-3" : "hidden"}
                                            >
                                                <label htmlFor="refund_type">การคืนเงินลูกค้า</label>
                                                <br />
                                                <label className="radio-inline ml-3" htmlFor="refund_type1">
                                                    <input type="radio" id="refund_type1" name="refund_type" value="1"
                                                           checked={this.state.refund_type === 1}
                                                           className="btn btn-primary"
                                                        //onChange={this.onRefundTypeChange}
                                                           onChange={(e) => {
                                                               let val = e.target.value
                                                               this.setState({
                                                                   refund_type: parseInt(val),
                                                                   refund_inpart : 0
                                                               })
                                                           }}
                                                    /> &nbsp; คืน
                                                </label>

                                                &nbsp;&nbsp;&nbsp;
                                                <label className="radio-inline ml-3" htmlFor="refund_type2">
                                                    <input type="radio" id="refund_type2" name="refund_type" value="2"
                                                           checked={this.state.refund_type === 2}
                                                           className="btn btn-primary"
                                                        //onChange={this.onRefundTypeChange}
                                                           onChange={(e) => {
                                                               let val = e.target.value
                                                               this.setState({
                                                                   refund_type: parseInt(val),
                                                                   refund_inpart : 0
                                                               })
                                                           }}
                                                    /> &nbsp; ไม่คืน
                                                </label>
                                                &nbsp;&nbsp;&nbsp;
                                                <label className="radio-inline ml-3" htmlFor="refund_type3">
                                                    <input type="radio" id="refund_type3" name="refund_type" value="3"
                                                           checked={this.state.refund_type === 3}
                                                           className="btn btn-primary"
                                                        //onChange={this.onRefundTypeChange}
                                                           onChange={(e) => {
                                                               let val = e.target.value
                                                               this.setState({
                                                                   refund_type: parseInt(val)
                                                               })
                                                           }}
                                                    /> &nbsp; บางส่วน
                                                </label>
                                            </div>

                                            <div
                                                className={checkFormControls('refund_type', form_controls) ? "form-group col-sm-3" : "hidden"}
                                            >
                                                <label htmlFor="refund_inpart">เงินคืนลูกค้าบางส่วน</label>
                                                <input type="text"
                                                       className="form-control hidden"
                                                       id="refund_inpart"
                                                       name="refund_inpart"
                                                       ref="refund_inpart"
                                                       value={this.state.refund_inpart || ''}
                                                       onChange={()=>{}}
                                                />

                                                <NumberFormat
                                                    className="form-control"
                                                    placeholder="กรอกเงินคืนลูกค้าบางส่วน"
                                                    thousandSeparator={true}
                                                    prefix={'฿'}
                                                    min={0}
                                                    max={1000000000}
                                                    step={1000}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.refund_inpart}
                                                    disabled={this.state.refund_type !== 3}
                                                    onValueChange={(values) => this.setState({refund_inpart: values.floatValue})}
                                                />
                                            </div>

                                            <div className="form-group col-sm-3">
                                                <label htmlFor="car_sell_status">สถานะ</label>
                                                <select className="form-control"
                                                        id="car_status_id"
                                                        name="car_status_id"
                                                        onChange={this.onInputChangeCarSellEdit}
                                                        value={this.state.car_status_id}
                                                >
                                                    {car_sell_status_list}
                                                </select>
                                            </div>

                                            {PORT === 9048 ? <>
                                                <div className="form-group col-sm-3">
                                                    {/*<label htmlFor="receipt_date">วันที่ออกรถ/ปล่อยรถ</label>
                                                    <br/>
                                                    <DatePicker
                                                        type="text"
                                                        className="form-control col-sm-12"
                                                        name="car_issue_date"
                                                        placeholder=""
                                                        selected={this.state.car_issue_date}
                                                        value={this.state.car_issue_date}
                                                        onChange={this.handleCarIssueDate}
                                                        dateFormat="yyyy-MM-dd"
                                                    />*/}

                                                  <BDatePicker
                                                    isDateFill={0}
                                                    name={'car_issue_date'}
                                                    value={this.state.car_issue_date}
                                                    label={'วันที่ออกรถ/ปล่อยรถ '}
                                                    onUpdateDate={(buddhist_date, christ_date) => {
                                                      this.setState({
                                                        car_issue_date: christ_date
                                                      })
                                                    }}
                                                  />

                                                </div>
                                            </> : null
                                            }

                                            <div
                                                className={checkFormControls('date_promise', form_controls) ? "form-group col-sm-3" : "hidden"}
                                            >
                                                {/*<label htmlFor="date_promise">วันที่ทำสัญญา/นัด </label>
                                                <DatePicker
                                                    selected={this.state.startDatePromiseEdit}
                                                    onChange={this.handleDatePromise}
                                                    dateFormat="yyyy-MM-dd"
                                                    type="text"
                                                    className="form-control"
                                                    id="date_promise"
                                                    name="date_promise"
                                                    ref="date_promise"
                                                    value={this.state.date_promise}
                                                    placeholder=""
                                                />*/}

                                              <BDatePicker
                                                isDateFill={0}
                                                name={'date_promise'}
                                                value={this.state.date_promise}
                                                label={'วันที่ทำสัญญา/นัด '}
                                                onUpdateDate={(buddhist_date, christ_date) => {
                                                  this.setState({
                                                    date_promise: christ_date
                                                  })
                                                }}
                                              />

                                            </div>

                                          <div
                                              className={checkFormControls('car_transfer_date', form_controls) ? "form-group col-sm-3" : "hidden"}
                                          >
                                            {/*<label htmlFor="car_transfer_date">วันที่โอนรถ/รับรถ </label> <br/>
                                            <DatePicker
                                              selected={this.state.car_transfer_date}
                                              onChange={this.handleCarDateTransfer}
                                              dateFormat="yyyy-MM-dd"
                                              type="text"
                                              className="form-control"
                                              id="car_transfer_date"
                                              name="car_transfer_date"
                                              value={this.state.car_transfer_date}
                                              placeholder=""
                                            />*/}

                                            <BDatePicker
                                              isDateFill={0}
                                              name={'car_transfer_date'}
                                              value={this.state.car_transfer_date}
                                              label={'วันที่โอนรถ/รับรถ '}
                                              onUpdateDate={(buddhist_date, christ_date) => {
                                                this.setState({
                                                  car_transfer_date: christ_date
                                                })
                                              }}
                                            />

                                          </div>

                                          <div
                                              className={checkFormControls('finance_payment_date', form_controls) && PORT !== 9043 ? "form-group col-sm-3" : "hidden"}
                                          >
                                           {/* <label htmlFor="finance_payment_date">วันที่รับเงิน(ไฟแนนซ์) </label> <br/>
                                            <DatePicker
                                              selected={this.state.finance_payment_date}
                                              onChange={this.handleFinancePaymentDate}
                                              dateFormat="yyyy-MM-dd"
                                              type="text"
                                              className="form-control"
                                              id="finance_payment_date"
                                              name="finance_payment_date"
                                              value={this.state.finance_payment_date}
                                              placeholder=""
                                            />*/}
                                            <BDatePicker
                                              isDateFill={0}
                                              name={'finance_payment_date'}
                                              value={this.state.finance_payment_date}
                                              label={'วันที่รับเงิน(ไฟแนนซ์) '}
                                              onUpdateDate={(buddhist_date, christ_date) => {
                                                this.setState({
                                                  finance_payment_date: christ_date
                                                })
                                              }}
                                            />

                                          </div>

                                            {
                                                port === 9046 ? <>
                                            <div className="form-group col-sm-4">
                                                <label htmlFor="more_detail_id">รายละเอียดเพิ่มเติม</label>
                                                <select
                                                    className="form-control"
                                                    id="more_detail_id"
                                                    name="more_detail_id"
                                                    ref="more_detail_id"
                                                    onChange={this.onInputChangeCarSellEdit}
                                                    value={this.state.more_detail_id}
                                                >
                                                    <option value=" ">เลือกรายการ</option>
                                                    {more_detail_list}
                                                </select>
                                            </div>

                                                </> : null
                                            }

                                        </div>
                                    </div>
                                </div>

                              <div className="row">
                                {/*<div className="form-group col-sm-3">
                                  <label htmlFor="insurance_pa">ประกันชีวิต PA</label>
                                  <input type="text"
                                         className="form-control hidden"
                                         id="insurance_pa"
                                         name="insurance_pa"
                                         placeholder=""
                                         value={this.state.insurance_pa}
                                         onChange={() => {}}
                                  />
                                  <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.insurance_pa}
                                    disabled={this.state.car_sell_type_id === '1'}
                                    onValueChange={(values) => this.setState({insurance_pa: values.floatValue})}
                                  />
                                </div>*/}

                                <div className="form-group col-sm-3">
                                  <label htmlFor="monthly_installment">ค่างวดรถต่อเดือน</label>
                                  <input type="text"
                                         className="form-control hidden"
                                         id="monthly_installment"
                                         name="monthly_installment"
                                         placeholder=""
                                         value={this.state.monthly_installment}
                                         onChange={() => {}}
                                  />
                                  <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.monthly_installment}
                                    disabled={this.state.car_sell_type_id === '1'}
                                    onValueChange={(values) => this.setState({monthly_installment: values.floatValue})}
                                  />
                                </div>

                                <div className="form-group col-sm-3">
                                  <label htmlFor="total_installment">รวมยอดผ่อน</label>
                                  <input type="text"
                                         className="form-control hidden"
                                         id="total_installment"
                                         name="total_installment"
                                         placeholder=""
                                         value={this.state.total_installment}
                                         onChange={() => {}}
                                  />
                                  <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    value={this.state.total_installment}
                                    allowNegative={false}
                                    disabled={this.state.car_sell_type_id === '1'}
                                    onValueChange={(values) => this.setState({total_installment: values.floatValue})}
                                  />
                                </div>

                                <div className="form-group col-sm-3">

                                  <BDatePicker
                                    isDateFill={0}
                                    name={'first_installment'}
                                    value={this.state.first_installment}
                                    label={'งวดแรก(วันที่) '}
                                    onUpdateDate={(buddhist_date, christ_date) => {
                                      this.setState({
                                        first_installment: christ_date
                                      })
                                    }}
                                  />

                                  {/*<label htmlFor="first_installment">งวดแรก(วันที่) </label>
                                  <br/>
                                  <DatePicker
                                    type="text"
                                    className="form-control col-sm-12"
                                    name="first_installment"
                                    placeholder=""
                                    selected={this.state.first_installment}
                                    value={this.state.first_installment}
                                    onChange={this.handleFistInstallmentDate}
                                    dateFormat="yyyy-MM-dd"
                                  />*/}

                                  {/*<input type="text"
                                         className="form-control hidden"
                                         id="first_installment"
                                         name="first_installment"
                                         placeholder=""
                                         value={this.state.first_installment}
                                         onChange={() => {}}
                                  />
                                  <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.first_installment}
                                    disabled={this.state.car_sell_type_id === '1'}
                                    onValueChange={(values) => this.setState({first_installment: values.floatValue})}
                                  />*/}
                                </div>

                                <div className="form-group col-sm-3">
                                  <label htmlFor="interest_installment">ดอกเบี้ยต่อปี</label>
                                  <input type="text"
                                         className="form-control hidden"
                                         id="interest_installment"
                                         name="interest_installment"
                                         placeholder=""
                                         value={this.state.interest_installment}
                                         onChange={() => {}}
                                  />
                                  <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'%'}
                                    min={0}
                                    max={100}
                                    step={0.1}
                                    size={3}
                                    allowNegative={false}
                                    value={this.state.interest_installment}
                                    disabled={this.state.car_sell_type_id === '1'}
                                    onValueChange={(values) => this.setState({interest_installment: values.floatValue})}
                                  />
                                </div>

                                <div className="form-group col-sm-3">
                                  <label htmlFor="liciense_status_id">สถานะเล่มทะเบียน</label>
                                  <select className="form-control"
                                          name="liciense_status_id"
                                          id="liciense_status_id"
                                          ref="liciense_status_id"
                                          value={this.state.liciense_status_id}
                                          onChange={this.onInputChangeCarSellEdit}
                                  >
                                      {liciense_status_list}
                                  </select>
                                </div>

                                  {PORT !== 9048 ? <>
                                      <div className="form-group col-sm-3">
                                          {/*<label htmlFor="receipt_date">วันที่ออกรถ/ปล่อยรถ</label>
                                          <br/>
                                          <DatePicker
                                              type="text"
                                              className="form-control col-sm-12"
                                              name="car_issue_date"
                                              placeholder=""
                                              selected={this.state.car_issue_date}
                                              value={this.state.car_issue_date}
                                              onChange={this.handleCarIssueDate}
                                              dateFormat="yyyy-MM-dd"
                                          />*/}

                                        <BDatePicker
                                          isDateFill={0}
                                          name={'car_issue_date'}
                                          value={this.state.car_issue_date}
                                          label={'วันที่ออกรถ/ปล่อยรถ '}
                                          onUpdateDate={(buddhist_date, christ_date) => {
                                            this.setState({
                                              car_issue_date: christ_date
                                            })
                                          }}
                                        />

                                      </div>
                                  </> : null
                                  }

                              </div>

                              <div className="row mt-2">
                                <div className="form-group col-sm-12">
                                  <label htmlFor="sell_comment">หมายเหตุการขาย</label>
                                  <ReactQuill theme="snow"
                                              value={this.state.sell_comment}
                                              onChange={(val) => {
                                                this.setState({
                                                  sell_comment: val
                                                })
                                              }}
                                  />
                                </div>
                              </div>

                            </div>

                            <div className="card-footer text-right">
                                <button type="reset"
                                        className="btn btn-sm btn-danger mr-2"
                                        onClick={this.props.onToggle}
                                >
                                    <i className="fa fa-refresh"/> ยกเลิก
                                </button>
                                <button type="submit"
                                        className="btn btn-sm btn-warning">
                                    <i className="fa fa-save"/> แก้ไขการขาย
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})
export default connect(mapStateToProps)(SalesEdit);
