import React, { useEffect, useState, useNavigate } from 'react'
import axios from 'axios'
import { APIURL, WEBURL } from '../../config/config'
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
// import { Redirect } from 'react-router-dom'
// import faker from 'faker'
// import { data, options } from '../../charts/DemoBar'
// import { backgroundColor } from 'html2canvas/dist/types/css/property-descriptors/background-color'
// import * as console from "console";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function CurrentOutstandingCarList() {

  // const navigate = useNavigate();
  const [outstandings, setOutstandings] = useState([])
  const [head, setHead] = useState([])
  const [value, setValue] = useState(0)

  useEffect( () => {

    async function loadCarOutstandings() {
      let results = await axios.get(APIURL+'/report_car/outstanding')
      let data = results.data
      setOutstandings(data.rows)
      setHead(data.head)
    }
    loadCarOutstandings()
  }, [])


  function goToDetail(value){
    console.log(value)
    setValue(value)
    const target = '/cars/list/1?type=summary&by=outstanding&value=' + value+'&name='
    // target={"_blank"}
    console.log(target)
   //  navigate(target)
    // return <Redirect push to={target}  />
  }

  function genBox() {

    if (outstandings.length === 0 ) {
      return null
    }

    let colors = [
      {color1: '#0ec44b', color2: '#99e2ab'},
      {color1: '#ffc803', color2: '#f1da89'},
      {color1: '#ff9002', color2: '#f3bb62'},
      {color1: '#e03939', color2: '#ffbbbb'},
    ]

    let boxs = []
    let len = outstandings.length - 1
    let values = [1,2,3,4]
    for (let i = len ; i >= 0  ; i--) {
      let outstanding = outstandings[i]
      let title = head[i]
      let color = colors[i]
      let value = values[i]
      const color1 = color.color1
      const color2 = color.color2

      const target = WEBURL+ '#/cars/list/1?type=summary&by=outstanding&value=' + value+'&name='+title+'&count='+outstanding

      let result = <div key={i}
                        className="card mt-2 p-2 pr-3 pl-3"
                        style={{background: `linear-gradient(to right,  ${color1} 0%,${color2} 100%)`, minHeight: 90, width: 380, borderRadius: 10}}
      >
        <div className="row">
          <div className="col-12 text-white font-xl">
            {title}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-6 text-white font-3xl">
            {outstanding}
          </div>
          <div className="col-6 text-right">
            <a href={target}
                    className="btn btn-primary"
                    style={{borderRadius: 20}}
            >
              ดูรายละเอียด
            </a>
          </div>
        </div>
      </div>
      boxs.push(result)
    }
     return boxs
  }

  function genChart() {
    const labels = head
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        title: {
          display: true,
          text: 'สรุปรายการรถคงค้างปัจจุบัน',
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          label: 'รถคงเหลือ',
          data: outstandings,
          backgroundColor: 'rgba(99, 99, 255, 1)',
        },
      ],
    };


    return <div style={{minWidth: 500, minHeight: 300}}>
      <Bar options={options} data={data} />
    </div>

  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header">
          <strong className="text-title">สรุปรายการรถคงค้างปัจจุบัน</strong>
        </div>
        <div className="card-block">
          <div className="row">
            <div className="col-5">
              {genBox()}
            </div>
            <div className="col-7">
              {genChart()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurrentOutstandingCarList
