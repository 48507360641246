import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import PreviewImage from '../../views/PreviewImage'
import axios from 'axios'
import { APIURL } from '../../config/config'

class DataLog extends Component{
  constructor (props){
    super(props)
    this.state = {
      data_logs: []
    }
    this.loadDataLog = this.loadDataLog.bind(this)
  }

  componentDidMount () {
    this.loadDataLog()
  }

  loadDataLog() {
    let table_name = this.props.table_name
    let ref_id = this.props.ref_id
    let menu_id = this.props.menu_id

    let criteria = {
      table_name,
      ref_id,
      menu_id
    }

    axios.post(APIURL+'/data_log/criteria', criteria).then(res => {
        // console.log(res.data)
        let data_logs = res.data
        this.setState({
          data_logs: data_logs
        })
    })

  }

  render() {

    const { data_logs } = this.state
    const trEmpty = <tr><td colSpan={3} className="text-center" >ไม่พบรายการการแก้ไข</td></tr>
    const trDataLog = data_logs.map((data_log,index) => {

      let data_log_details = data_log.data_log_details

      return (
        <tr key={index}>
          <td>{data_log.user_created}</td>
          <td>{data_log.created_at}</td>
          <td>
            <table className="table table-warning">
              <thead>
               <tr>
                 <th>ชื่อข้อมูล</th>
                 <th>ข้อมูลเดิม</th>
                 <th>ข้อมูลใหม่</th>
               </tr>
              </thead>
              <tbody>
              {
                data_log_details.map((detail, index2) => (
                  <tr key={index2}>
                    <td>{detail.label_name}</td>
                    <td>{detail.old_value === '0000-00-00' ? '-' : detail.old_value}</td>
                    <td>{detail.new_value}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </td>
        </tr>
      )

    })
    let trData = data_logs.length === 0 ? trEmpty: trDataLog
    let title = this.props.title

    return (
      <Modal isOpen={this.props.is_open} toggle={this.props.onToggle}
             className={'modal-lg '}
             style={{maxWidth:1000}}
      >
        <ModalHeader toggle={this.props.onToggle}>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 ">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center" style={{width: 100}} >ผู้แก้ไข</th>
                    <th className="text-center" style={{width: 120}} >วันเวลาที่แก้ไข</th>
                    <th className="text-center">รายละเอียดการแก้ไข</th>
                  </tr>
                </thead>
                <tbody>
                  {trData}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.props.onClose}>&nbsp;&nbsp; ปิด &nbsp;&nbsp;</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default DataLog